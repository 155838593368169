import { OpenInNew } from "@mui/icons-material";
import { default as AccountCircleIcon } from "@mui/icons-material/AccountCircle";
import AlbumIcon from "@mui/icons-material/Album";
import BusinessIcon from "@mui/icons-material/Business";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	styled,
	Toolbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import signature from "../assets/signature_light.png";
import { LibraryWrapper } from "../components/LibraryWrapper";
import { LoginModal } from "../components/LoginModal";
import { PlayerFab } from "../components/PlayerFab";
import { ScrollTop } from "../components/ScrollToTop";
import { UserAvatar } from "../components/UserAvatar";
import { VolumeSlider } from "../components/VolumeSlider";
import { logout } from "../firebase";
import { useAuthState } from "../state/auth.state";
import { Modals, useModalsState } from "../state/modal.state";
import styles from "./AppLayout.module.css";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	background: "none",
	backgroundColor: "none",
	boxShadow: "none",
	color: theme.palette.primary.main,
	padding: theme.spacing(1, 0, 1, 0),
}));

export const AppLayout = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { user, pending, isAdmin } = useAuthState();
	const { open } = useModalsState();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const [searchParams, setSearchParams] = useSearchParams();

	const modalQuery = searchParams.get("modal");

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleDrawer = (event?: any) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setDrawerOpen(!drawerOpen);
	};

	const handleLoginLogoutClick = () => {
		if (!user && !pending) {
			open(Modals.LOGIN);
		} else {
			logout();
			navigate("/");
		}
		setDrawerOpen(false);
		setAnchorEl(null);
	};

	useEffect(() => {
		if (modalQuery) {
			open(modalQuery as Modals);
			//TODO reset modal state
			setSearchParams({});
		}
	}, [modalQuery, open, setSearchParams]);

	const menuOpen = Boolean(anchorEl);

	return (
		<Box sx={{ width: "100%" }}>
			<StyledAppBar position="static">
				<Toolbar id="back-to-top-anchor">
					<IconButton
						edge="start"
						sx={{ mr: 2 }}
						color="inherit"
						aria-label="menu"
						onClick={toggleDrawer}
						size="large"
					>
						<MenuIcon fontSize="large" />
					</IconButton>
					<Box
						sx={(theme) => ({
							paddingTop: theme.spacing(1),
							flexGrow: 1,
							display: "flex",
							justifyContent: "center",
						})}
					>
						<Link to={`/`} className={styles.link}>
							<img src={signature} alt="" height={70} />
						</Link>
					</Box>

					<div>
						{user ? (
							<>
								<IconButton
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
									size="large"
								>
									<UserAvatar size={"48px"} />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={menuOpen}
									onClose={handleClose}
								>
									<MenuItem onClick={handleLoginLogoutClick}>
										{user ? "Logout" : "Login"}
									</MenuItem>
									{user ? (
										<Link
											to="/profile"
											className={styles.link}
										>
											<MenuItem>Profile</MenuItem>
										</Link>
									) : null}
								</Menu>
							</>
						) : (
							<>
								<Button
									aria-label="Login"
									onClick={handleLoginLogoutClick}
									color="inherit"
								>
									Login or register
								</Button>
							</>
						)}
					</div>
				</Toolbar>
				<Drawer
					anchor="left"
					open={drawerOpen}
					onClose={toggleDrawer}
					PaperProps={{
						sx: {
							backgroundColor: "#000000",
							backgroundImage: "none",
						},
					}}
				>
					<Box
						sx={(theme) => ({
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: theme.spacing(2, 0, 2, 0),
						})}
					>
						<img src={signature} alt="" height={100} />
					</Box>
					<List sx={{ width: 300 }}>
						<Link
							to="/"
							onClick={toggleDrawer}
							className={styles.link}
						>
							<ListItem button>
								<ListItemIcon>
									<AlbumIcon />
								</ListItemIcon>
								<ListItemText primary={"Library"} />
							</ListItem>
						</Link>
						{isAdmin ? (
							<Link
								to="/admin"
								onClick={toggleDrawer}
								className={styles.link}
							>
								<ListItem button>
									<ListItemIcon>
										<BusinessIcon />
									</ListItemIcon>
									<ListItemText primary={"Admin"} />
								</ListItem>
							</Link>
						) : null}
						{user ? (
							<Link
								to="/profile"
								onClick={toggleDrawer}
								className={styles.link}
							>
								<ListItem button>
									<ListItemIcon>
										<AccountCircleIcon />
									</ListItemIcon>
									<ListItemText primary={"Profile"} />
								</ListItem>
							</Link>
						) : null}
					</List>
					<Divider />
					<List sx={{ width: 300 }}>
						<ListItem button onClick={handleLoginLogoutClick}>
							<ListItemIcon>
								{user ? (
									<MeetingRoomIcon />
								) : (
									<AccountCircleIcon />
								)}
							</ListItemIcon>
							<ListItemText primary={user ? "Logout" : "Login"} />
						</ListItem>
					</List>
					<Divider />
					<List sx={{ width: 300 }}>
						<a
							rel="noreferrer noopener"
							target="_blank"
							className={styles.link}
							href="https://nikdaijai.com"
							onClick={toggleDrawer}
						>
							<ListItem button>
								<ListItemIcon>
									<OpenInNew />
								</ListItemIcon>
								<ListItemText primary={"Website"} />
							</ListItem>
						</a>
						<a
							rel="noreferrer noopener"
							target="_blank"
							className={styles.link}
							href="https://nikdaijai.com/impressum"
							onClick={toggleDrawer}
						>
							<ListItem button>
								<ListItemIcon>
									<OpenInNew />
								</ListItemIcon>
								<ListItemText primary={"Impressum"} />
							</ListItem>
						</a>
						<a
							rel="noreferrer noopener"
							target="_blank"
							className={styles.link}
							href="https://nikdaijai.com/datenschutz"
							onClick={toggleDrawer}
						>
							<ListItem button>
								<ListItemIcon>
									<OpenInNew />
								</ListItemIcon>
								<ListItemText
									primary={"Datenschutzerklärung"}
								/>
							</ListItem>
						</a>
					</List>
				</Drawer>
			</StyledAppBar>
			<LibraryWrapper>
				<Outlet />
			</LibraryWrapper>
			<Box
				sx={(theme) => ({
					zIndex: 1001,
					position: "fixed",
					bottom: theme.spacing(2),
					right: theme.spacing(2),
					display: "flex",
					flexDirection: "column",
					"& > *": {
						marginBottom: theme.spacing(1),
					},
				})}
			>
				<PlayerFab />
				{isDesktop ? <VolumeSlider /> : null}
				<ScrollTop />
			</Box>
			<LoginModal />
		</Box>
	);
};
