import "firebase/firestore";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Collection } from "../model/collection.enum";
import { Download } from "../model/download.model";

export function useDownloads() {
	const [items, setItems] = useState<Download[]>([]);
	const [pending, setPending] = useState<boolean>(true);
	useEffect(() => {
		return onSnapshot(
			collection(getFirestore(), Collection.DOWNLOADS),
			(snapshot) => {
				setItems(
					snapshot.docs.map(
						(d) =>
							({
								id: d.id,
								...d.data(),
							} as Download)
					)
				);
				setPending(false);
			}
		);
	}, []);
	return { items, pending };
}
