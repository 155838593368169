import { Zoom } from "@mui/material";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { debounce } from "lodash";
import { useCallback } from "react";
import { usePlayerState } from "../state/player.state";
import {
	getDefaultVolumeFromLocalStorage,
	setVolume,
} from "../utils/audio.util";

const StyledZoom = styled(Zoom)(({ theme }) => ({
	height: 200,
	borderRadius: 4,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(3),
	background: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: "transparent",
	height: "100%",
}));

export const VolumeSlider: React.FC = () => {
	const defaultVolume = getDefaultVolumeFromLocalStorage();

	const { audioId } = usePlayerState();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onVolumeChangeDebounced = useCallback(
		debounce((value: number) => {
			setVolume(value);
		}, 5),
		[setVolume]
	);

	const handleVolumeChange = (e: any, value: any) => {
		onVolumeChangeDebounced(value);
	};

	return (
		<StyledZoom in={Boolean(audioId)}>
			<div role="presentation">
				<StyledSlider
					size="small"
					orientation="vertical"
					defaultValue={defaultVolume}
					onChange={handleVolumeChange}
					aria-labelledby="Volume"
					valueLabelDisplay="auto"
					valueLabelFormat={(x: number) => `${x}%`}
				/>
			</div>
		</StyledZoom>
	);
};
