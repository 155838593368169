import { createTheme, CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initFirebase } from "./app/firebase";
import { RootView } from "./app/RootView";
import "./app/style/fonts/dmsans/dmsans.css";
import { ErrorPage } from "./app/views/ErrorView";

export const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#FFFFFF",
			contrastText: "#000000",
		},
		secondary: {
			main: "#FFFFFF",
			contrastText: "#000000",
		},
		background: {
			default: "#121212",
		},
	},
	typography: {
		fontFamily: '"Dm Sans", Sans-Serif',
	},
});

const queryClient = new QueryClient();

initFirebase();

const router = createBrowserRouter([
	{
		path: "/*",
		element: <RootView />,
		// probably never used because of catch all redirects
		errorElement: <ErrorPage />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<SnackbarProvider maxSnack={3}>
						<CssBaseline />
						<RouterProvider router={router} />
						<ReactQueryDevtools initialIsOpen={false} />
					</SnackbarProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);
