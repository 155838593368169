import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Container,
	TextField,
	Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	applyActionCode2,
	confirmNewPassword2,
	loginWithEmailAndPassword2,
	verifyPwResetCode,
} from "../firebase";
import { Modals } from "../state/modal.state";
import { logger } from "../utils/logger.util";

type FormData = {
	password: string;
};

const ResetPasswordView: React.FC = () => {
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [pending, setPending] = useState<boolean>(false);
	const [checkPending, setCheckPending] = useState<boolean>(false);
	const [checkError, setCheckError] = useState<boolean>(false);

	const [email, setEmail] = useState<string | null>(null);

	const oobCode = searchParams.get("oobCode");

	useEffect(() => {
		setCheckPending(true);
		if (oobCode) {
			verifyPwResetCode(oobCode)
				.then((e) => {
					setEmail(e);
				})
				.catch((error) => {
					logger.log("error verifying pw reset code", error);
					const message =
						error.message ||
						"There was an error with your password reset link";
					setCheckError(true);
					enqueueSnackbar(message, { variant: "error" });
				});
		}
		setCheckPending(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oobCode]);

	const goToLogin = () => {
		navigate(`/?modal=${Modals.LOGIN}`);
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<FormData>();

	const onSubmit = async ({ password }: FormData) => {
		setPending(true);

		try {
			if (!oobCode) {
				throw new Error("No code found");
			}
			logger.log("onSubmit", { password });
			await confirmNewPassword2(oobCode, password);

			if (email) {
				await loginWithEmailAndPassword2(email, password);
				enqueueSnackbar(
					"Success! You are now logged in with your new password",
					{ variant: "success" }
				);
				navigate("/");
			} else {
				enqueueSnackbar(
					"Success! You can now log in with your new password",
					{
						variant: "success",
					}
				);
				goToLogin();
			}
		} catch (error: any) {
			const message = error.message || "Error resetting password";
			enqueueSnackbar(message, { variant: "error" });
			logger.log("error resetting password", error);
		}
		setPending(false);
	};

	const passwordHasErrors = Boolean(errors.password);

	if (checkError || checkPending) {
		return (
			<Container maxWidth="sm">
				<Card>
					<CardContent>
						{checkPending ? (
							<>
								<Typography component="h1" variant="h5">
									Verifying link...
								</Typography>
								<CircularProgress />
							</>
						) : null}
						{checkError ? (
							<Typography component="h1" variant="h5">
								Link is invalid. Please request a new link
							</Typography>
						) : null}
					</CardContent>
					{checkError ? (
						<CardActions>
							<Button variant="contained" onClick={goToLogin}>
								Go to login
							</Button>
						</CardActions>
					) : null}
				</Card>
			</Container>
		);
	}

	return (
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					<Avatar
						sx={(theme) => ({
							m: 1,
							backgroundColor: theme.palette.secondary.main,
						})}
					>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Reset password
					</Typography>
					<Typography variant="body1">
						Enter a new password for <b>{email}</b>
					</Typography>
					<Box
						component="form"
						sx={{
							width: "100%",
							mt: 1,
						}}
						noValidate
						onSubmit={handleSubmit(onSubmit)}
					>
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							label={
								passwordHasErrors
									? "Please enter a password with at least 8 characters"
									: "Password"
							}
							type="password"
							id="password"
							error={passwordHasErrors}
							inputProps={{
								...register("password", {
									required: true,
									minLength: 8,
								}),
							}}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							sx={{
								mt: 3,
								mb: 2,
							}}
							disabled={pending || !isValid}
						>
							{pending ? <CircularProgress /> : "Update password"}
						</Button>
					</Box>
				</CardContent>
			</Card>
		</Container>
	);
};

const EmailVerifiedView: React.FC = () => {
	const [searchParams] = useSearchParams();

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const [success, setSuccess] = useState<boolean>(false);
	const [pending, setPending] = useState<boolean>(false);
	const [checkError, setCheckError] = useState<boolean>(false);

	const oobCode = searchParams.get("oobCode");

	useEffect(() => {
		setPending(true);
		if (oobCode) {
			applyActionCode2(oobCode)
				.then(() => {
					setSuccess(true);
					enqueueSnackbar("Yout email is now verified", {
						variant: "success",
					});
					setTimeout(() => {
						navigate("/");
					}, 3000);
				})
				.catch((error) => {
					logger.error(
						"error verifying email verification code",
						error
					);
					const message =
						error.message ||
						"There was an error with your password reset link";
					setCheckError(true);
					enqueueSnackbar(message, { variant: "error" });
				});
		}
		setPending(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oobCode]);

	const goToLogin = () => {
		navigate(`/?modal=${Modals.LOGIN}`);
	};

	return (
		<Container maxWidth="sm">
			<Card>
				<CardContent>
					{pending ? (
						<>
							<Typography component="h1" variant="h5">
								Verifying link...
							</Typography>
							<CircularProgress />
						</>
					) : null}
					{checkError ? (
						<Typography component="h1" variant="h5">
							This link appears to be invalid. Please request a
							new link from your profile page.
						</Typography>
					) : null}
				</CardContent>
				{success ? (
					<CardContent>
						<Typography variant="h3">
							Your email is now verified
						</Typography>
					</CardContent>
				) : null}
				{checkError || success ? (
					<CardActions>
						<Button variant="contained" onClick={goToLogin}>
							Go to login
						</Button>
					</CardActions>
				) : null}
			</Card>
		</Container>
	);
};

export const AuthRedirectHandlerView: React.FC = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const mode = searchParams.get("mode");

	const goToLogin = () => {
		navigate(`/?modal=${Modals.LOGIN}`);
	};

	logger.log("AuthRedirectHandlerView", { mode });

	switch (mode) {
		case "resetPassword":
			return <ResetPasswordView />;
		// case 'recoverEmail':
		//   // Display email recovery handler and UI.
		//   handleRecoverEmail(auth, actionCode, lang);
		//   break;
		case "verifyEmail":
			return <EmailVerifiedView />;
		default:
			return (
				<Container maxWidth="sm">
					<Card>
						<CardContent>
							<Typography component="h1" variant="h5">
								Something went wrog. If you expected something
								different please contact contact@nikdaijai.com
							</Typography>
						</CardContent>
						<CardActions>
							<Button variant="contained" onClick={goToLogin}>
								Go to login
							</Button>
						</CardActions>
					</Card>
				</Container>
			);
		// Error: invalid mode.
	}
};
