import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { DownloadList } from "../components/DownloadList";
import { AcceptedFileType, FileUploadArea } from "../components/FileUploadArea";
import { LoadingView } from "../components/LoadingView";
import { TrackList } from "../components/TrackList";
import { UsersList } from "../components/UsersList";
import { dumpData, uploadFile } from "../firebase";
import { deleteTrack } from "../firebase/functions.firebase";
import { useAudioFiles } from "../hooks/audio-files.hook";
import { useAuthState } from "../state/auth.state";
import { Modals, useModalsState } from "../state/modal.state";

const TabPanel: React.FC<any> = (props) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index ? <Box p={1}>{children}</Box> : null}
		</div>
	);
};

type TabWrapperProps = PropsWithChildren<{
	currentView: number;
	viewId: number;
}>;

export const TabWrapper = ({
	children,
	currentView,
	viewId,
}: TabWrapperProps) => {
	return (
		<TabPanel value={currentView} index={viewId}>
			{children}
		</TabPanel>
	);
};

const UploadView: React.FC = () => {
	const { files } = useAudioFiles();
	const processingFiles = files.filter((f) => f.uploading || f.converting);
	const unreleasedFiles = files.filter(
		(f) => !f.released && !f.uploading && !f.converting
	);

	console.log({ processingFiles });

	const handleTrackUpload = async (files: File[]) => {
		for (const file of files) {
			await uploadFile(file);
		}
	};

	const handleRemove = (id: string) => async () => {
		await deleteTrack(id);
	};

	return (
		<>
			<FileUploadArea
				onUpload={handleTrackUpload}
				accept={AcceptedFileType.AUDIO}
			/>
			{processingFiles.length > 0 ? (
				<>
					<List>
						{processingFiles.map((f) => (
							<ListItem
								key={f.id}
								secondaryAction={
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={handleRemove(f.id)}
									>
										<CloseIcon />
									</IconButton>
								}
							>
								<ListItemAvatar>
									<CircularProgress />
								</ListItemAvatar>
								<ListItemText
									primary={f.title}
									secondary={
										f.uploading
											? "Uploading"
											: f.converting
											? "Converting"
											: "Done"
									}
								/>
							</ListItem>
						))}
					</List>
				</>
			) : null}
			{unreleasedFiles.length > 0 ? (
				<>
					<Typography variant="h5" gutterBottom>
						Unreleased files
					</Typography>
					<TrackList
						files={files.filter((f) => !f.released)}
						editable
					/>
				</>
			) : null}
		</>
	);
};

export const LibraryAdmin: React.FC = () => {
	const { user, pending, isAdmin } = useAuthState();
	const [view, setView] = useState<number>(0);

	const { files } = useAudioFiles();
	const { open } = useModalsState();

	const handleChange = (_: any, v: number) => {
		setView(v);
	};

	const handleLoginClick = () => {
		open(Modals.LOGIN);
	};

	if (pending) {
		return <LoadingView message="Loading admin" />;
	}

	if (!user || !isAdmin) {
		return (
			<>
				<Button variant="contained" onClick={handleLoginClick}>
					Login
				</Button>
			</>
		);
	}

	const views = [
		{
			title: "Upload",
			content: <UploadView />,
		},
		{
			title: "Released tracks",
			content: (
				<TrackList files={files.filter((f) => f.released)} editable />
			),
		},
		{
			title: "Downloads",
			content: <DownloadList />,
		},
		{
			title: "Users",
			content: <UsersList files={files} />,
		},
		{
			title: "Settings",
			content: (
				<Button variant="contained" onClick={dumpData(files)}>
					Export data
				</Button>
			),
		},
	];

	return (
		<>
			<Typography variant="h4" component="h4" gutterBottom>
				{`Admin > ${views[view]?.title}`}
			</Typography>
			<Tabs
				value={view}
				onChange={handleChange}
				scrollButtons
				indicatorColor="primary"
				variant="scrollable"
				textColor="primary"
				aria-label="Admin view navigation"
				sx={{
					mb: 2,
				}}
				allowScrollButtonsMobile
			>
				{views.map((v) => (
					<Tab key={v.title} label={v.title} />
				))}
			</Tabs>
			{views.map((v, i) => (
				<TabWrapper key={v.title} currentView={view} viewId={i}>
					{v.content}
				</TabWrapper>
			))}
		</>
	);
};
