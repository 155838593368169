import { Grid, Typography } from "@mui/material";
import { WaveSpinner } from "react-spinners-kit";

type Props = { message?: string };

export const LoadingView = ({ message }: Props) => {
	return (
		<Grid
			container
			alignItems="center"
			justifyContent="center"
			spacing={2}
			direction="column"
			sx={{
				height: "100%",
				minHeight: "80vh",
			}}
		>
			<Grid item xs={12}>
				<WaveSpinner color="#FFFFFF" loading={true} />
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant="body2"
					color="textSecondary"
					align="center"
				>
					{message || "Loading"}
				</Typography>
			</Grid>
		</Grid>
	);
};
