import { Typography } from "@mui/material";
import React from "react";
import { LoadingView } from "../components/LoadingView";
import { TrackList } from "../components/TrackList";
import { useAudioFiles } from "../hooks/audio-files.hook";

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.

export const LibraryHome: React.FC = () => {
	const { files, loading } = useAudioFiles();
	const releasedItems = files.filter((f) => f.released);

	return (
		<>
			{loading ? (
				<LoadingView message="Loading library" />
			) : (
				<>
					<Typography variant="h4" component="h4">
						Production music library
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						maxWidth={600}
						sx={{ mb: 2 }}
					>
						Welcome to my Production music library! Use the tags or
						search function to find different styles and tracks. You
						can create an account, to add tracks to your favorites
						list. Enjoy!
					</Typography>
					<TrackList files={releasedItems} />
				</>
			)}
		</>
	);
};
