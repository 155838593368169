import {
	applyActionCode,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	getAuth,
	GoogleAuthProvider,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithRedirect,
	signOut,
	verifyPasswordResetCode,
} from "firebase/auth";
import "firebase/firestore";
import { logger } from "../utils/logger.util";
import { setNewsletter } from "./firestore.firebase";

export const loginWithGoogle = () => {
	return signInWithRedirect(getAuth(), new GoogleAuthProvider());
};

export const signUpWithEmailAndPassword = (
	email: string,
	password: string,
	newsletter: boolean
) => {
	const auth = getAuth();
	return createUserWithEmailAndPassword(auth, email, password).then(
		async (u) => {
			const currentUser = auth.currentUser;
			if (currentUser) {
				await setNewsletter(currentUser, newsletter);
				sendVerifyEmail().then(() => {
					// Email verification sent!
					// ...
					logger.log("mail verification sent");
				});
			} else {
				console.warn("no user after signup");
			}
			return u;
		}
	);
};

export const sendVerifyEmail = () => {
	logger.log("sendEmailVerification");
	const auth = getAuth();
	const currentUser = auth.currentUser;

	if (!currentUser) {
		throw new Error("You need to be logged in for this");
	}

	return sendEmailVerification(currentUser);
};

export const resetPassword = (email: string) => {
	logger.log("resetPassword", email);
	return sendPasswordResetEmail(getAuth(), email);
};

export const verifyPwResetCode = async (code: string) => {
	return verifyPasswordResetCode(getAuth(), code);
};

export const applyActionCode2 = async (code: string) => {
	return applyActionCode(getAuth(), code);
};

export const confirmNewPassword2 = async (code: string, password: string) => {
	return confirmPasswordReset(getAuth(), code, password);
};

export const loginWithEmailAndPassword2 = (email: string, password: string) => {
	logger.log("loginWithEmailAndPassword", email);
	return signInWithEmailAndPassword(getAuth(), email, password);
};

export const logout = () => signOut(getAuth());
