import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Chip } from "@mui/material";
import React, { useState } from "react";

type Props = {
	tags: string[];
	selected: string[];
	onSelect: (tag: string) => unknown;
};

export const TagCloud: React.FC<Props> = ({ tags, selected, onSelect }) => {
	const [expanded, setExpanded] = useState(false);

	const maxVisible = 7;

	const tagsToRender = expanded ? tags : tags.slice(0, maxVisible);

	const remainingCount = Math.max(tags.length - maxVisible, 0);

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	return (
		<>
			{tagsToRender.map((tag) => {
				const handleClick = (t: string) => () => {
					onSelect(t);
				};
				const isSelected = selected.includes(tag);
				return (
					<Chip
						color={isSelected ? "primary" : "default"}
						sx={{ mr: 1, mb: 1 }}
						key={tag}
						label={tag}
						onClick={handleClick(tag)}
					/>
				);
			})}
			<Button
				endIcon={expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				onClick={toggleExpanded}
				sx={{ mr: 1, mb: 1 }}
			>
				{expanded ? "less" : `show ${remainingCount} more`}
			</Button>
		</>
	);
};
