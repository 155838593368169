import { useQuery } from "@tanstack/react-query";
import "firebase/auth";
import {
	addDoc,
	arrayRemove,
	arrayUnion,
	collection,
	doc,
	getCountFromServer,
	getFirestore,
	query,
	setDoc,
	Timestamp,
	updateDoc,
	where,
} from "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import { TrackData } from "../model/audio-file.info.model";
import { Collection } from "../model/collection.enum";
import { FirebaseUser } from "../model/firebase.model";
import { logger } from "../utils/logger.util";
import {
	DEFAULT_ARTIST,
	DEFAULT_COMPOSER,
	DEFAULT_LABELCODE,
} from "./constants";

export const setShowcased = (id: string, showcased: boolean) => {
	logger.log("setShowcased", id, showcased);
	return updateAudioFile(id, {
		showcased,
	});
};

export const createNewAudioFileInfo = async (fileName: string) => {
	logger.log("createNewAudioFileInfo", fileName);
	const newAudioFileData: Omit<TrackData, "id"> = {
		description: null,
		tags: [],
		createdAt: Timestamp.now(),
		released: false,
		uploading: true,
		showcased: false,
		converting: false,
		title: fileName,
		artist: DEFAULT_ARTIST,
		composer: DEFAULT_COMPOSER,
		gema: null,
		labelcode: DEFAULT_LABELCODE,
		storageObject: null,
		storageMp3Ref: null,
		storageWavRef: null,
		storageRef: null,
	};

	return addDoc(
		collection(getFirestore(), Collection.AUDIO),
		newAudioFileData
	);
};

export const addToFavorites = async (user: FirebaseUser, audio: TrackData) => {
	logger.log("addToFavorites", user, audio);

	return updateDoc(doc(getFirestore(), Collection.USERS, user.uid), {
		favorites: arrayUnion(audio.id),
	});
};

export const setNewsletter = async (user: FirebaseUser, enabled: boolean) => {
	logger.log("setNewsletter", user, enabled);

	return setDoc(
		doc(getFirestore(), Collection.USERS, user.uid),
		{
			newsletter: enabled,
		},
		{ merge: true }
	);
};

export const createUserDoc = async (user: FirebaseUser) => {
	logger.log("createUserDoc", user);
	return setDoc(
		doc(getFirestore(), Collection.USERS, user.uid),
		{},
		{ merge: true }
	);
};

export const removeFromFavorites = async (
	user: FirebaseUser,
	audio: TrackData
) => {
	logger.log("removeFromFavorites", user, audio);
	return updateDoc(doc(getFirestore(), Collection.USERS, user.uid), {
		favorites: arrayRemove(audio.id),
	});
};

export const updateReleaseState = async (
	file: TrackData,
	released: boolean
) => {
	logger.log("updateReleaseState", file, released);
	return updateAudioFile(file.id, {
		released,
	});
};

export const updateAudioFile = async (
	id: string,
	newValues: Partial<Omit<TrackData, "id">>
) => {
	return setDoc(doc(getFirestore(), Collection.AUDIO, id), newValues, {
		merge: true,
	});
};

export function usePlayCount(track: TrackData) {
	const { isLoading, data } = useQuery({
		queryKey: ["playCount", track.id],
		queryFn: () =>
			Promise.all([
				getCountFromServer(
					query(
						collection(getFirestore(), Collection.PLAYS),
						where("file.id", "==", track.id)
					)
				).then((res) => res.data().count),
				getCountFromServer(
					query(
						collection(getFirestore(), Collection.DOWNLOADS),
						where("file.id", "==", track.id)
					)
				).then((res) => res.data().count),
			]),
	});

	return {
		isLoading,
		plays: data?.at(0) ?? 0,
		downloads: data?.at(1) ?? 0,
	};
}

export const dumpData = (files: TrackData[]) => () => {
	const json = JSON.stringify({ files }, null, 2);

	const blob = new Blob([Buffer.from(json)]);
	const blobUrl = URL.createObjectURL(blob);
	var link = document.createElement("a");
	link.href = blobUrl;
	link.download = "dump.json";
	link.target = "_blank";
	link.click();
};
