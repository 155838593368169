import { Container } from "@mui/material";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

export const LibraryWrapper = ({ children }: PropsWithChildren) => {
	return (
		<Container
			maxWidth="lg"
			sx={{
				mt: 2,
				mb: 10,
				width: "100%",
			}}
		>
			<Outlet />
		</Container>
	);
};
