import { getApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import {
	connectFunctionsEmulator,
	getFunctions,
	httpsCallable,
} from "firebase/functions";
import { TrackData } from "../model/audio-file.info.model";
import { logger } from "../utils/logger.util";
import { emulatorsEnabled } from "./util.firebase";

export const FUNCTIONS_REGION = "europe-west3";

export const euFunctions = () => {
	const functions = getFunctions(getApp(), FUNCTIONS_REGION);
	if (emulatorsEnabled()) {
		logger.log("Using local function emulator");
		connectFunctionsEmulator(functions, "localhost", 5001);
	}
	return functions;
};

export async function callCloudFunction(
	name: string,
	data?: Record<string, any>
) {
	const callable = httpsCallable(euFunctions(), name);

	console.log("Call function", { name, data });

	const result = await callable(data);

	return result;
}

export const removeTrackImage = async (track: TrackData) => {
	logger.log("removeTrackImage", track);

	await callCloudFunction("removeTrackImage", {
		id: track.id,
	});
};

export const trackPlay = async (track: TrackData) => {
	try {
		await callCloudFunction("trackPlay", {
			track,
		});
	} catch (error) {
		console.log(error);
	}
};

export const deleteTrack = async (id: string) => {
	try {
		if (window.confirm(`Really?`)) {
			await callCloudFunction("deleteTrack", {
				id,
			});
		}
	} catch (error) {
		console.log(error);
	}
};
