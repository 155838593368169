import { createState, DevTools } from "@hookstate/core";
import { isDevOrDebug } from "../../utils/env.util";
import { createLoggingPlugin } from "./state-watch-plugin";

export type StateInitConfig = {
	label: string;
};

export const createMonitoredState = <T>(
	initialState: T,
	options: StateInitConfig
) => {
	const state = createState<T>(initialState);
	//label is broken https://github.com/avkonst/hookstate/issues/76
	// In redux dev tools select one of the "global-state-x"
	if (isDevOrDebug()) {
		DevTools(state).label(options.label);
		state.attach(createLoggingPlugin(options.label));
	}

	return state;
};
