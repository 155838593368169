import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export function useWasVisible() {
	const ref = useRef(null);
	const inView = useInView(ref);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (inView) {
			setVisible(true);
		}
	}, [inView]);

	return {
		ref,
		visible,
	};
}
