import { isDevelopmentEnvironment } from "./env.util";

const isDev = isDevelopmentEnvironment();

if (!isDev) {
	console.log("logging disabled");
}

const logOnlyInDev = (fun: any, ...args: any) => {
	if (isDev) {
		fun(...args);
	}
};

export function logStateEventGrouped(
	label: string,
	action: string,
	changedValue: any,
	completeState: any
) {
	if (isDev) {
		console.groupCollapsed(label.toUpperCase(), action, "=", changedValue);
		console.log("State", completeState);
		console.groupEnd();
	}
}

export const logger = {
	log: (...args: any) => logOnlyInDev(console.log, ...args),
	error: (...args: any) => logOnlyInDev(console.error, ...args),
	warn: (...args: any) => logOnlyInDev(console.warn, ...args),
};
