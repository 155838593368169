import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import GetAppIcon from "@mui/icons-material/GetApp";
import ShareIcon from "@mui/icons-material/Share";
import StarIcon from "@mui/icons-material/Star";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from "@mui/material";
import { yellow } from "@mui/material/colors";
import { formatDistanceToNow } from "date-fns";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
	addToFavorites,
	downloadFile,
	getStorageUrl,
	removeFromFavorites,
	setShowcased,
	updateReleaseState,
	usePlayCount,
} from "../../firebase";
import { deleteTrack } from "../../firebase/functions.firebase";
import { useWasVisible } from "../../hooks/use-was-visible";
import { TrackData } from "../../model/audio-file.info.model";
import { useAuthState } from "../../state/auth.state";
import { Modals, useModalsState } from "../../state/modal.state";
import { getExtension } from "../../utils/filepath";
import { logger } from "../../utils/logger.util";
import { AudioWaveFormPlayer } from "../AudioWaveFormPlayer";
import { InlinePlayButton } from "../InlinePlayButton";
import { TrackEditor } from "./TrackEditor";

type Props = {
	track: TrackData;
	editable?: boolean;
	expandable?: boolean;
	noActions?: boolean;
	availableTags?: string[];
	showInfo?: boolean;
	defaultExpanded?: boolean;
	fixedHeight?: boolean;
	showBgImage?: boolean;
};

const FIRST_CELL_STYLE: React.CSSProperties = {
	whiteSpace: "nowrap",
	border: "none",
};
const SECOND_CELL_STYLE: React.CSSProperties = {
	width: "100%",
	border: "none",
};

const PlayStats = ({ track }: { track: TrackData }) => {
	const { plays, downloads } = usePlayCount(track);
	return (
		<Box sx={{ mx: 2, p: 2 }}>
			<Grid container>
				<Grid item xs={3}>
					<Typography variant="h4">{plays}</Typography>
					<Typography variant="body1">Plays</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography variant="h4">{downloads}</Typography>
					<Typography variant="body1">Downloads</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export const TrackCard: React.FC<Props> = ({
	track,
	editable,
	expandable = true,
	availableTags = [],
	noActions = false,
	showInfo = true,
	fixedHeight = false,
	defaultExpanded = false,
	showBgImage = false,
}) => {
	const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [downloadPending, setDownloadPending] = useState<string | null>(null);
	const { ref, visible } = useWasVisible();
	const { isAdmin, user, data } = useAuthState();
	const { open } = useModalsState();
	const isFavorited = data?.favorites.includes(track.id);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (track.image) {
			getStorageUrl(track.image).then((u) => {
				setImageUrl(u);
			});
		} else {
			setImageUrl(null);
		}
	}, [track.image]);

	const shareLink = `${window.location.origin}/track/${track.id}`;

	const handleDownload = (storageRef: string) => async () => {
		setDownloadPending(storageRef);
		try {
			const res = await downloadFile({ file: track, user, storageRef });
			const blob = new Blob([res]);
			const blobUrl = URL.createObjectURL(blob);
			var link = document.createElement("a");
			link.href = blobUrl;

			const extension = getExtension(storageRef);

			const nameParts = [track.artist, track.title, track.gema];

			const downloadFileName = `${nameParts
				.filter(Boolean)
				.join("-")}.${extension}`.replace(/\s/g, "_");

			link.download = downloadFileName;
			link.target = "_blank";
			link.click();
		} catch (error) {
			logger.error("Error downloading file", error);
		} finally {
			setDownloadPending(null);
		}
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const dateString = formatDistanceToNow(track.createdAt.toDate(), {
		addSuffix: true,
	});

	const handleDelete = async () => {
		await deleteTrack(track.id);
	};

	const toggleRelease = () => {
		logger.log("Release", track);
		enqueueSnackbar(
			`${track.title} ${track.released ? "unreleased" : "released"}`,
			{ variant: "success" }
		);
		updateReleaseState(track, !track.released);
	};

	const handleToggleShowcased = () => {
		logger.log("handleToggleShowcased");
		setShowcased(track.id, !track.showcased);
		enqueueSnackbar(
			`${track.title} ${!track.showcased ? "showcased" : "unshowcased"}`,
			{ variant: "success" }
		);
	};

	const displayRow = (valKey: keyof TrackData, title: string) =>
		track[valKey] ? (
			<TableRow key={valKey} hover>
				<TableCell style={FIRST_CELL_STYLE}>{title}</TableCell>
				<TableCell style={SECOND_CELL_STYLE}>
					{String(track[valKey])}
				</TableCell>
			</TableRow>
		) : null;

	const downloadButton = (title: string, ref?: string | null) => {
		if (!ref) {
			return null;
		}
		return (
			<Button
				disabled={downloadPending === ref}
				onClick={handleDownload(ref)}
				startIcon={<GetAppIcon />}
			>
				{downloadPending === ref ? (
					<CircularProgress size={25} />
				) : (
					title
				)}
			</Button>
		);
	};

	const handleAddToFavorites = () => {
		if (user) {
			if (isFavorited) {
				removeFromFavorites(user, track);
			} else {
				addToFavorites(user, track);
			}
		} else {
			open(Modals.LOGIN);
		}
	};

	const handleShare = () => {
		console.log("Share link");
		enqueueSnackbar("Link copied to clipboard", { variant: "success" });
	};

	if (track.uploading) {
		return (
			<Card>
				<CardHeader
					sx={{ cursor: "pointer" }}
					title={track.title}
					subheader={"Uploading"}
				></CardHeader>
			</Card>
		);
	}

	const heartIconColor = { color: "#fb3958" };
	const starIconColor = { color: yellow[500] };

	const bgStyles: React.CSSProperties = {
		background: `linear-gradient(120deg, #1E1E1E 50%, transparent), url(${imageUrl})`,
		backgroundSize: "cover",
		backgroundPosition: "right center",
		backgroundRepeat: "no-repeat",
		transition: "background 300ms",
	};
	const bg = imageUrl && showBgImage ? bgStyles : {};
	return (
		<Card style={bg} ref={ref}>
			<CardHeader
				avatar={<InlinePlayButton track={track} />}
				sx={{
					overflow: "hidden",
					cursor: fixedHeight ? "initial" : "pointer",
				}}
				title={
					<Typography
						variant="h6"
						noWrap={fixedHeight}
						onClick={expandable ? handleExpandClick : undefined}
					>
						{track.title}
					</Typography>
				}
				subheader={`${track.tags.slice().sort().join(", ")}`}
				subheaderTypographyProps={{
					noWrap: fixedHeight,
					onClick: expandable ? handleExpandClick : undefined,
				}}
				action={
					expandable ? (
						<IconButton
							sx={(theme) => ({
								transform: expanded
									? "rotate(180deg)"
									: "rotate(0deg)",
								marginLeft: "auto",
								transition: theme.transitions.create(
									"transform",
									{
										duration:
											theme.transitions.duration.shortest,
									}
								),
							})}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
							size="large"
						>
							<ExpandMoreIcon />
						</IconButton>
					) : null
				}
			></CardHeader>
			{isAdmin && visible ? <PlayStats track={track} /> : null}
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				{editable ? (
					<TrackEditor track={track} availableTags={availableTags} />
				) : (
					<>
						{showInfo ? (
							<CardContent>
								<TableContainer>
									<Table
										sx={{
											minWidth: 300,
										}}
										size="small"
									>
										<TableBody>
											{displayRow("artist", "Artist")}
											{displayRow("composer", "Composer")}
											{displayRow(
												"gema",
												"GEMA Werknummer"
											)}
											{displayRow(
												"labelcode",
												"Labelcode"
											)}
											<TableRow hover>
												<TableCell
													style={FIRST_CELL_STYLE}
												>
													Uploaded
												</TableCell>
												<TableCell
													style={SECOND_CELL_STYLE}
												>
													{dateString}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						) : null}
						<CardContent>
							<Typography variant="body1">
								{track.description || ""}
							</Typography>
						</CardContent>
					</>
				)}
				<CardContent
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<AudioWaveFormPlayer track={track} />
				</CardContent>
			</Collapse>
			{noActions ? null : (
				<CardActions>
					<IconButton
						aria-label="add to favorites"
						onClick={handleAddToFavorites}
						size="large"
					>
						{isFavorited ? (
							<FavoriteIcon style={heartIconColor} />
						) : (
							<FavoriteBorderIcon />
						)}
					</IconButton>
					<CopyToClipboard text={shareLink}>
						<IconButton onClick={handleShare} size="large">
							<ShareIcon />
						</IconButton>
					</CopyToClipboard>

					<Divider orientation="vertical" flexItem />
					{downloadButton("MP3", track.storageMp3Ref)}
					{downloadButton("WAV", track.storageWavRef)}
					{editable && isAdmin ? (
						<>
							{downloadButton("ORIGINAL", track.storageRef)}
							<Divider orientation="vertical" flexItem />
							<IconButton
								aria-label="set showcased"
								onClick={handleToggleShowcased}
								size="large"
							>
								<StarIcon
									style={track.showcased ? starIconColor : {}}
								/>
							</IconButton>
							<Button onClick={toggleRelease}>
								{track.released ? "Un-Release" : "Release"}
							</Button>
							<Divider orientation="vertical" flexItem />
							<IconButton onClick={handleDelete} size="large">
								<DeleteForeverIcon />
							</IconButton>
						</>
					) : null}
				</CardActions>
			)}
		</Card>
	);
};
