import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { CircularProgress, Fade, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { TrackData } from "../model/audio-file.info.model";
import { usePlayerState } from "../state/player.state";
import { pauseAudio, playAudio, unpauseAudio } from "../utils/audio.util";
const PREFIX = "InlinePlayButton";

const classes = {
	root: `${PREFIX}-root`,
	player: `${PREFIX}-player`,
	playIconWrapper: `${PREFIX}-playIconWrapper`,
	playButton: `${PREFIX}-playButton`,
	progressWrapper: `${PREFIX}-progressWrapper`,
};

const Root = styled("div")(({ theme }) => ({
	[`&.${classes.root}`]: {
		position: "relative",
	},

	[`& .${classes.player}`]: {
		flexGrow: 1,
		borderRadius: 4,
		overflow: "hidden",
	},

	[`& .${classes.playIconWrapper}`]: {
		// paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(2),
	},

	[`& .${classes.playButton}`]: {
		backgroundColor: "rgba(255,255,255,0.2)",
	},

	[`& .${classes.progressWrapper}`]: {
		position: "absolute",
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		opacity: 0.5,
	},
}));

export const InlinePlayButton: React.FC<{ track: TrackData }> = ({ track }) => {
	const { fetching, playing, audioId, progress } = usePlayerState();

	const showProgress = audioId === track.id && playing;
	const showLoading = audioId === track.id && fetching && !playing;

	const togglePlay = async () => {
		if (audioId === track.id) {
			if (playing) {
				pauseAudio();
			} else {
				unpauseAudio();
			}
		} else {
			playAudio(track);
			// trackPlay(track);
		}
	};

	return (
		<Root className={classes.root}>
			{showProgress ? (
				<div className={classes.progressWrapper}>
					<Fade in={showProgress}>
						<CircularProgress
							size={48}
							value={progress * 100}
							variant="determinate"
							thickness={1}
						/>
					</Fade>
				</div>
			) : null}
			{showLoading ? (
				<div className={classes.progressWrapper}>
					<Fade in={showLoading}>
						<CircularProgress
							size={48}
							variant="indeterminate"
							thickness={1}
						/>
					</Fade>
				</div>
			) : null}
			<IconButton
				disableRipple
				onClick={togglePlay}
				aria-label="play/pause"
				disabled={fetching && audioId === track.id}
				className={classes.playButton}
				size="large"
			>
				{playing && audioId === track.id ? (
					<PauseIcon />
				) : (
					<PlayArrowIcon />
				)}
			</IconButton>
		</Root>
	);
};
