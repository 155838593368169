import {
	Box,
	Button,
	CardActions,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
	getStorageUrl,
	updateAudioFile,
	uploadTrackImageFile,
} from "../../firebase";
import { removeTrackImage } from "../../firebase/functions.firebase";
import { TrackData } from "../../model/audio-file.info.model";
import { logger } from "../../utils/logger.util";
import { AcceptedFileType, FileUploadArea } from "../FileUploadArea";

type Props = { track: TrackData; availableTags: string[] };

type FormProps = Pick<
	TrackData,
	"gema" | "description" | "title" | "artist" | "composer" | "labelcode"
>;

export const TrackEditor = ({ track, availableTags }: Props) => {
	const [tags, setTags] = useState<string[]>(track.tags);
	const [showcaseTag, setShowcaseTag] = useState<string | null>(
		track.showcaseTag || null
	);
	const { register, handleSubmit, getValues, watch } = useForm<FormProps>();
	const { enqueueSnackbar } = useSnackbar();
	const [imageUrl, setImageUrl] = useState<string | null>(null);

	useEffect(() => {
		if (track.image) {
			getStorageUrl(track.image).then((u) => {
				setImageUrl(u);
			});
		} else {
			setImageUrl(null);
		}
	}, [track.image]);

	const isAnyFieldChanged = () => {
		const formKeys = Array.from(Object.keys(getValues()));
		const formKeyChanged = formKeys
			.map((k) => k as keyof FormProps)
			.map((k) => {
				const val = watch(k);
				return Boolean(val && val !== track[k]);
			})
			.some((k) => k === true);

		return (
			formKeyChanged ||
			!isEqual(track.tags, tags) ||
			!isEqual(track.showcaseTag, showcaseTag)
		);
	};

	const handleTagsChange = async (e: any, value: any) => {
		logger.log("handleTagsChange", value, value || track.tags || []);
		setTags(value || track.tags || []);
	};

	const handleShowcaseTagChange = async (e: any, value: any) => {
		logger.log("handleShowcaseTagChange", value);
		// setTags(value || file.tags || []);
		setShowcaseTag(value || "");
	};

	const onSubmit = async (data: FormProps) => {
		logger.log("onSubmit", data);
		try {
			await updateAudioFile(track.id, {
				description: data.description || track.description || "",
				title: data.title || track.title || "",
				artist: data.artist || track.artist || "",
				composer: data.composer || track.composer || "",
				labelcode: data.labelcode || track.labelcode || "",
				gema: data.gema || track.gema || "",
				tags: tags || track.tags || [],
				showcaseTag: showcaseTag || null,
			});
			enqueueSnackbar(`Info updated`, { variant: "success" });
		} catch (error) {
			console.log("Error saving file", error);
			enqueueSnackbar("Error updating file info", {
				variant: "error",
			});
		}
	};

	const formField = (valKey: keyof TrackData, label: string) => (
		<TextField
			defaultValue={track[valKey] || ""}
			variant="standard"
			fullWidth
			label={label}
			sx={{ mb: 2 }}
			{...register(valKey as keyof FormProps)}
		/>
	);

	const handleImageUpload = async (files: File[]) => {
		try {
			console.log("Upload image", files);
			const file = files.find(Boolean);
			if (file) {
				await uploadTrackImageFile(track, file);
			} else {
				enqueueSnackbar(`No suitable file found`, {
					variant: "error",
				});
			}
		} catch (error: any) {
			enqueueSnackbar(`Error adding image: ${error.message}`, {
				variant: "error",
			});
		}
	};

	const handleRemoveImage = async (e: React.SyntheticEvent) => {
		e.stopPropagation();
		try {
			await removeTrackImage(track);
		} catch (error: any) {
			enqueueSnackbar(`Error deleting image: ${error.message}`, {
				variant: "error",
			});
		}
	};

	return (
		<>
			<form
				noValidate
				autoComplete="off"
				onSubmit={handleSubmit(onSubmit)}
			>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h4" sx={{ mb: 2 }}>
								Info
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{formField("title", "Title")}
						</Grid>
						<Grid item xs={12}>
							{formField("description", "Description")}
						</Grid>
						<Grid item xs={12}>
							<Autocomplete
								multiple
								freeSolo
								sx={{ mb: 2 }}
								options={availableTags}
								defaultValue={track.tags || []}
								getOptionLabel={(option: string) => option}
								onChange={handleTagsChange}
								filterOptions={(options, params) => {
									const newVal = params.inputValue
										? [params.inputValue.trim()]
										: [];
									return [...newVal, ...options];
								}}
								renderInput={(params: any) => (
									<TextField
										{...params}
										variant="standard"
										label="Tags"
										placeholder="The mood of the song. e.g. dark"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}></Grid>
						<Grid item xs={12}>
							{formField("artist", "Artist")}
						</Grid>
						<Grid item xs={12}>
							{formField("composer", "Composer")}
						</Grid>
						<Grid item xs={12} sm={6}>
							{formField("labelcode", "Labelcode")}
						</Grid>
						<Grid item xs={12} sm={6}>
							{formField("gema", "GEMA Werknummer")}
						</Grid>
					</Grid>

					<Autocomplete
						freeSolo
						options={availableTags}
						defaultValue={track.showcaseTag || ""}
						getOptionLabel={(option: string) => option}
						onChange={handleShowcaseTagChange}
						filterOptions={(options, params) => {
							const newVal = params.inputValue
								? [params.inputValue.trim()]
								: [];
							return [...newVal, ...options];
						}}
						renderInput={(params: any) => (
							<TextField
								{...params}
								variant="standard"
								label="Showcase tag"
								placeholder="The tag the track is showcased in. e.g. experimental"
							/>
						)}
					/>
					<Typography variant="h4" sx={{ my: 2 }}>
						Title Image
					</Typography>
					<FileUploadArea
						onUpload={handleImageUpload}
						accept={AcceptedFileType.IMAGE}
					>
						{imageUrl ? (
							<Box
								sx={{
									position: "relative",
									backgroundImage: `url(${imageUrl})`,
									backgroundSize: "contain",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									width: "100%",
									height: 300,
								}}
							>
								<Button
									onClick={handleRemoveImage}
									sx={{
										position: "absolute",
										bottom: 12,
										right: 12,
									}}
								>
									Remove image
								</Button>
							</Box>
						) : null}
					</FileUploadArea>
				</CardContent>
				<CardActions>
					<Button
						disabled={!isAnyFieldChanged()}
						variant="contained"
						type="submit"
					>
						Save
					</Button>
				</CardActions>
			</form>
		</>
	);
};
