import Dexie from "dexie";
import { downloadFile } from "../firebase";
import { TrackData } from "../model/audio-file.info.model";
import { logger } from "./logger.util";

const db = new Dexie("AUDIO_CACHE");

type FileData = {
	fileId: string;
	mp3Buffer: Buffer;
};

db.version(1).stores({
	files: "fileId,mp3Buffer",
});

const filesTable: Dexie.Table<FileData, number> = db.table("files");

async function saveToIndexDb(item: FileData) {
	try {
		await filesTable.put(item);
	} catch (error) {
		logger.error("Error saving filebuffer", error);
	}
}

async function getFromIndexDb(fileId: string) {
	try {
		const items = await filesTable.where("fileId").equals(fileId).toArray();
		return items[0];
	} catch (error) {
		logger.error("Error getting from indexdb", error);
	}
	return null;
}

export const getAudioFileBuffer = async (track: TrackData) => {
	try {
		const existing = await getFromIndexDb(track.id);

		if (existing) {
			console.log("getFileBuffer", { existing });
			return existing.mp3Buffer;
		}

		if (track.storageMp3Ref) {
			const buffer = await downloadFile({
				file: track,
				storageRef: track.storageMp3Ref,
				user: null,
				skipTracking: true,
			});

			if (buffer) {
				saveToIndexDb({
					fileId: track.id,
					mp3Buffer: buffer,
				});
				return buffer;
			}
		}
	} catch (error) {
		logger.error("Error getting audio buffer", error);
	}

	return null;
};
