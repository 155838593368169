import { State, useState } from "@hookstate/core";
import { createMonitoredState } from "./utils/create-store";

export enum Modals {
	LOGIN = "LOGIN",
}

export interface ModalState {
	openModal: Modals | null;
	lastOpenModal: Modals | null;
}

const state = createMonitoredState<ModalState>(
	{
		openModal: null,
		lastOpenModal: null,
	},
	{ label: "modals" }
);

const wrapper = (state: State<ModalState>) => ({
	get state() {
		return state;
	},
	isOpen: (modal: Modals) => state.value.openModal === modal,
	open: (modal: Modals) => {
		state.set((s) => ({
			...s,
			openModal: modal,
			lastOpenModal: modal,
		}));
	},
	close: () => {
		state.openModal.set(null);
	},
	toggle: () => {
		state.set((s) => ({
			...s,
			openModal: s.openModal !== null ? null : s.lastOpenModal,
		}));
	},
});

export const globalModalsState = () => wrapper(state);
export const useModalsState = () => wrapper(useState(state));
