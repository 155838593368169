import { Navigate, Route, Routes } from "react-router-dom";
import { Showcase } from "./components/Showcase";
import { AppLayout } from "./layout/AppLayout";
import { BaseLayout } from "./layout/BaseLayot";
import { AuthRedirectHandlerView } from "./views/AuthRedirectHandler";
import { ErrorPage } from "./views/ErrorView";
import { LibraryAdmin } from "./views/LibraryAdmin";
import { LibraryHome } from "./views/LibraryHome";
import { ProfileView } from "./views/Profile";
import { TrackDetail } from "./views/TrackDetail";

export const RootView = () => {
	return (
		<Routes>
			<Route element={<BaseLayout />} errorElement={<ErrorPage />}>
				<Route path="/showcase" element={<Showcase />} />
			</Route>
			<Route element={<AppLayout />} errorElement={<ErrorPage />}>
				<Route
					path="/auth-redirect"
					element={<AuthRedirectHandlerView />}
				/>
				<Route path="/profile" element={<ProfileView />} />
				<Route path="/admin" element={<LibraryAdmin />} />
				<Route path="/track/:id" element={<TrackDetail />} />
				<Route path="/showcase" element={<Showcase />} />
				<Route path="/" element={<LibraryHome />} />
				<Route path="*" element={<Navigate to="/" />} />
			</Route>
		</Routes>
	);
};
