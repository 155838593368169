import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { LoadingView } from "../components/LoadingView";
import { TrackCard } from "../components/track/TrackCard";
import { useAudioFile } from "../hooks/audio-files.hook";

export const TrackDetail: React.FC = () => {
	let { id } = useParams<{ id: string }>();

	const { file, loading } = useAudioFile(id);

	console.log({ file, loading });

	if (loading) {
		return <LoadingView message={"Loading track"} />;
	}

	if (!file) {
		return null;
	}

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Link to="/" style={{ textDecoration: "none" }}>
					<Button startIcon={<ArrowBackIcon />}>
						{" "}
						Back to production music library
					</Button>
				</Link>
			</Grid>
			<Grid item xs={12}>
				<TrackCard
					track={file}
					defaultExpanded
					expandable={false}
					showBgImage={true}
				/>
			</Grid>
		</Grid>
	);
};
