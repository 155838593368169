import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Fab, Zoom } from "@mui/material";
import React from "react";
import { usePlayerState } from "../state/player.state";
import { pauseAudio, unpauseAudio } from "../utils/audio.util";

export const PlayerFab: React.FC = () => {
	const { playing, audioId } = usePlayerState();

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (playing) {
			pauseAudio();
			return;
		}

		if (!playing && audioId) {
			unpauseAudio();
			return;
		}
	};

	return (
		<Zoom in={Boolean(audioId)}>
			<div onClick={handleClick} role="presentation">
				<Fab
					color="secondary"
					size="small"
					aria-label="play/pause audio"
				>
					{playing && audioId ? <PauseIcon /> : <PlayArrowIcon />}
				</Fab>
			</div>
		</Zoom>
	);
};
