import { Box, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { LegacyRef, useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import { TrackData } from "../model/audio-file.info.model";
import { usePlayerState } from "../state/player.state";
import { getAudioFileBuffer } from "../utils/audio-cache.util";
import { seekTo } from "../utils/audio.util";
import { logger } from "../utils/logger.util";
import { InlinePlayButton } from "./InlinePlayButton";

const StyledDiv = styled("div")(({ theme }) => ({
	pointerEvents: "none",
	zIndex: 1,
	overflow: "hidden",
	transition: "opacity 2s ease-in-out",
}));

const WAVEFORM_HEIGHT = 100;

type Props = {
	track: TrackData;
};

export const AudioWaveFormPlayer: React.FC<Props> = ({ track }) => {
	const waveformRef = useRef<WaveSurfer | null>(null);
	const playerOverlayRef = useRef<HTMLDivElement>(null);
	const visibilityRef = useRef<HTMLDivElement>(null);
	// const [url, setUrl] = useState<string | null>();
	const { audioId, progress, playing } = usePlayerState();
	const [buffer, setBuffer] = useState(null);
	const [wInstance, setWInstance] = useState<null | WaveSurfer>(null);
	const hasOverlay = audioId !== track.id;
	const [isVisible, setIntersecting] = useState(false);

	useEffect(() => {
		const el = visibilityRef?.current;
		const observer = new IntersectionObserver(([entry]) => {
			setIntersecting(entry.isIntersecting);
		});
		if (el) {
			observer.observe(el);
		}
		return () => {
			if (el) {
				observer.unobserve(el);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visibilityRef.current]);

	useEffect(() => {
		// console.log("useEffect audiobuffer");
		if (!buffer && isVisible) {
			getAudioFileBuffer(track)
				.then((b) => {
					logger.log("Waveform init", track, b);
					if (b) {
						setBuffer(b);
					}
				})
				.catch((e) => {
					logger.error("Error loading waveform", e);
				});
		}

		if (buffer && !isVisible) {
			setBuffer(null);
		}
	}, [track, buffer, isVisible]);

	useEffect(() => {
		// console.log("Trigger Create waveform", {
		// 	buffer,
		// 	curr: waveformRef.current,
		// 	// isVisible,
		// });

		if (buffer && waveformRef.current) {
			console.log("Create waveform");
			const instance = WaveSurfer.create({
				container: waveformRef.current as any,
				barWidth: 2,
				// cursorColor: hasOverlay ? "transparent" : "#FFFFFF",
				cursorColor: "transparent",
				progressColor: "rgba(255,255,255,1)",
				waveColor: "rgba(255,255,255,0.5)",
				cursorWidth: 2,
				normalize: true,
				responsive: true,
				hideScrollbar: true,
				height: WAVEFORM_HEIGHT,
			});
			instance.loadBlob(new Blob([buffer]));
			instance.setVolume(0);
			instance.on("error", (e) => {
				console.log(e);
			});
			setWInstance(instance);
			return () => {
				if (instance) {
					instance.destroy();
					setWInstance(null);
				}
			};
		}
	}, [buffer]);

	useEffect(() => {
		if ((audioId && audioId === track.id) || progress === 0) {
			try {
				if (wInstance) {
					wInstance.seekTo(progress);
				}
			} catch (error: any) {
				logger.log(
					"error handling player progress change",
					error.message
				);
			}
		}
	}, [progress, audioId, track.id, wInstance]);

	useEffect(() => {
		try {
			if (wInstance) {
				if (!playing && hasOverlay) {
					wInstance.setCursorColor("transparent");
					return;
				}
				if (playing && !hasOverlay) {
					wInstance.setCursorColor("#FFFFFF");
				}
			}
		} catch (error: any) {
			logger.log("error setting player cursor color", error.message);
		}
	}, [playing, hasOverlay, wInstance]);

	const handleSeekLayerClick = async (e: any) => {
		let rect = e.target.getBoundingClientRect();
		let x = e.clientX - rect.left;
		const width = playerOverlayRef.current?.offsetWidth || 0;
		const progress = width > 0 ? x / width : 0;
		seekTo(track, progress);
	};

	const playButton = <InlinePlayButton track={track} />;

	return (
		<Fade in={true} timeout={1000}>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					minHeight: WAVEFORM_HEIGHT,
				}}
				ref={visibilityRef}
			>
				<Box
					sx={{
						flexGrow: 1,
						borderRadius: 4,
						overflow: "hidden",
						position: "relative",
					}}
				>
					<StyledDiv
						sx={{
							opacity: isVisible ? 1 : 0,
						}}
						ref={waveformRef as LegacyRef<HTMLDivElement>}
					/>
					<Box
						ref={playerOverlayRef}
						sx={{
							position: "absolute",
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							zIndex: 1000,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						onClick={handleSeekLayerClick}
					>
						{hasOverlay ? playButton : null}
					</Box>
				</Box>
			</Box>
		</Fade>
	);
};
