import { Downgraded, State, useState } from "@hookstate/core";
import { FirebaseUser } from "../model/firebase.model";
import { mapToUserData, UserData } from "../model/user-data.model";
import { createMonitoredState } from "./utils/create-store";

export interface AuthState {
	user: FirebaseUser | null;
	pending: boolean;
	isAdmin: boolean;
	data: UserData;
}

const allowedEmails = [
	"root@codedrift.net",
	"nikdaijai@gmail.com",
	"nikolaij@nikdaijai.com",
	"perscheidnikolaij@googlemail.com",
];

const state = createMonitoredState<AuthState>(
	{
		user: null,
		pending: true,
		isAdmin: false,
		data: mapToUserData(null),
	},
	{ label: "auth" }
);

const wrapper = (state: State<AuthState>) => ({
	get state() {
		return state.attach(Downgraded).get();
	},
	get pending() {
		return state.pending.get();
	},
	get isAdmin() {
		return state.isAdmin.get();
	},
	get user() {
		return state.user.get();
	},
	get data() {
		return state.data.get();
	},
	updateUser: (user: FirebaseUser | null) => {
		state.set((s) => ({
			...s,
			pending: false,
			user,
			isAdmin:
				user && user.email ? allowedEmails.includes(user.email) : false,
		}));
	},
	updateUserData: (userData: UserData) => {
		state.data.set(userData);
	},
});

export const globalAuthState = () => wrapper(state);
export const useAuthState = () => wrapper(useState(state));
