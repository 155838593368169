import Axios from "axios";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { TrackData } from "../model/audio-file.info.model";
import { Collection } from "../model/collection.enum";
import { FirebaseUser } from "../model/firebase.model";
import { getExtension } from "../utils/filepath";
import { logger } from "../utils/logger.util";
import { createNewAudioFileInfo, updateAudioFile } from "./firestore.firebase";
import { callCloudFunction } from "./functions.firebase";

export const uploadFile = async (file: File) => {
	logger.log("uploadFile", file);
	const extension = getExtension(file.name);

	const { id } = await createNewAudioFileInfo(file.name);

	const storageRef = `${Collection.AUDIO}/${id}/main.${extension}`;

	await updateAudioFile(id, {
		storageRef,
	});

	const fileRef = ref(getStorage(), storageRef);

	const res = await uploadBytes(fileRef, file, {
		customMetadata: {
			originalName: file.name,
			audioId: id,
		},
	});

	logger.log("File uploaded", res);
};

export const getPublicBucketUrl = async (storageRef: string) => {
	logger.log("getPublicBucketUrl", storageRef);
	const r = ref(getStorage(), storageRef);
	const url = await getDownloadURL(r);
	return url;
};

type DownloadOptions = {
	file: TrackData;
	user?: FirebaseUser | null;
	storageRef?: string | null;
	skipTracking?: boolean;
};

export const downloadFile = async (options: DownloadOptions) => {
	const { user, file, storageRef, skipTracking = false } = options;

	logger.log("downloadFile", file, storageRef);
	if (!storageRef) {
		return;
	}

	if (!skipTracking) {
		callCloudFunction("trackDownload", {
			storageRef,
			file,
			user: {
				email: user?.email || "unknown",
				uid: user?.uid || "unknown",
			},
		});
	}

	const r = ref(getStorage(), storageRef);
	const downloadUrl = await getDownloadURL(r);

	const { data } = await Axios.get(downloadUrl, {
		responseType: "arraybuffer",
	});

	return data;
};

export const getStorageUrl = async (storageRef: string) => {
	const r = ref(getStorage(), storageRef);
	const downloadUrl = await getDownloadURL(r);
	return downloadUrl;
};

export const dumpData = (files: TrackData[]) => () => {
	const json = JSON.stringify({ files }, null, 2);

	const blob = new Blob([Buffer.from(json)]);
	const blobUrl = URL.createObjectURL(blob);
	var link = document.createElement("a");
	link.href = blobUrl;
	link.download = "dump.json";
	link.target = "_blank";
	link.click();
};

export const uploadTrackImageFile = async (track: TrackData, file: File) => {
	logger.log("uploadTrackImageFile", track, file);

	const storage = getStorage();

	const baseImageRef = ref(
		storage,
		`${Collection.AUDIO}/${track.id}/images/main.noext`
	);

	const uploadRes = await uploadBytes(baseImageRef, file);

	console.log(uploadRes);
};
