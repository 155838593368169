import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useMemo } from "react";
import { useUsers } from "../hooks/users.hook";
import { TrackData } from "../model/audio-file.info.model";

type Props = {
	files: TrackData[];
};

export const UsersList = ({ files }: Props) => {
	const { items } = useUsers();

	const idToNameMap = useMemo(() => {
		const m = new Map<string, string>();
		files.forEach((t) => m.set(t.id, t.title ?? "-"));
		return m;
	}, [files]);

	return (
		<Grid container spacing={1}>
			{items.map((i) => {
				const dateString = formatDistanceToNow(i.createdAt.toDate(), {
					addSuffix: true,
				});

				const rows = [
					{
						title: "Admin",
						value: String(i.isAdmin),
					},
					{
						title: "CreatedAt",
						value: dateString,
					},
					{
						title: `Favorites (${i.favorites.length})`,
						value: i.favorites
							.map((f) => idToNameMap.get(f))
							.join(", "),
					},
					{
						title: `Newsletter`,
						value: Boolean(i.newsletter),
					},
				];

				return (
					<Grid item xs={12} key={i.id}>
						<Card>
							<CardHeader title={i.email || i.id} />
							<CardContent>
								<TableContainer>
									<Table
										sx={{ minWidth: 650 }}
										aria-label="simple table"
									>
										<TableBody>
											{rows.map((row) => (
												<TableRow
													key={row.title}
													sx={{
														"&:last-child td, &:last-child th":
															{ border: 0 },
													}}
												>
													<TableCell>
														{row.title}
													</TableCell>
													<TableCell>
														{row.value}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</Grid>
				);
			})}
		</Grid>
	);
};
