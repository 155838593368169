import {
	Box,
	Card,
	CardMedia,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import { useMemo } from "react";
import useResizeObserver from "use-resize-observer";
import cinematicImage from "../assets/tags/small/cinematic.webp";
import computerImage from "../assets/tags/small/computer.webp";
import countryImage from "../assets/tags/small/country.webp";
import experimentalImage from "../assets/tags/small/experimental.webp";
import pianoImage from "../assets/tags/small/piano.webp";
import { useAudioFiles } from "../hooks/audio-files.hook";
import { logger } from "../utils/logger.util";
import { TrackCard } from "./track/TrackCard";

enum Tag {
	CINEMATIC = "cinematic",
	ELECTRONIC = "electronic",
	COUNTRY = "country",
	PIANO = "piano",
	EXPERIMENTAL = "experimental",
}

type TagInfo = { image: string; title: string };

const TAG_DESCRIPTIONS = new Map<Tag, TagInfo>([
	[
		Tag.CINEMATIC,
		{
			image: cinematicImage,
			title: "Cinematic",
		},
	],
	[
		Tag.ELECTRONIC,
		{
			image: computerImage,
			title: "Electronic",
		},
	],
	[
		Tag.COUNTRY,
		{
			image: countryImage,
			title: "Country",
		},
	],
	[
		Tag.PIANO,
		{
			image: pianoImage,
			title: "Piano",
		},
	],
	[
		Tag.EXPERIMENTAL,
		{
			image: experimentalImage,
			title: "Experimental",
		},
	],
]);

type Props = { tag: Tag };

const ShowCaseSongs = ({ tag }: Props) => {
	const { files } = useAudioFiles();

	const tagDesc = TAG_DESCRIPTIONS.get(tag);

	const taggedFiles = files
		.filter((f) => f.showcased)
		.filter((f) => f.showcaseTag === tag)
		.slice(0, 2);

	const title = tagDesc ? tagDesc.title : tag;
	const image = tagDesc ? tagDesc.image : cinematicImage;

	const memoFiles = useMemo(
		() =>
			taggedFiles.map((f) => (
				<Grid xs={12} item key={f.id}>
					<TrackCard
						key={f.id}
						track={f}
						editable={false}
						availableTags={[]}
						noActions
						fixedHeight
						expandable={true}
						showInfo={false}
					/>
				</Grid>
			)),
		[taggedFiles]
	);

	if (taggedFiles.length === 0) {
		logger.log("Not enough showcased files found ", { tag });
		return null;
	}

	return (
		<Grid item xs={12} sm={6}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card
						sx={{
							width: "100%",
							position: "relative",
						}}
					>
						<CardMedia
							sx={{
								height: 0,
								paddingTop: "20%", // 16:9
								filter: "brightness(50%)",
							}}
							image={image}
							title={title}
						/>
						<Box
							sx={{
								position: "absolute",
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								display: "flex",
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									pl: 2,
									margin: 0,
									fontSize: "2rem",
									fontWeight: "bold",
								}}
								variant="h5"
								component="h5"
								gutterBottom
								align="center"
							>
								{title}
							</Typography>
						</Box>
					</Card>
				</Grid>
				{memoFiles}
			</Grid>
		</Grid>
	);
};

export const Showcase = () => {
	const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();

	const postWindowSize = () => {
		const message = {
			source: "nikdaijai-production-library",
			payload: { height },
		};
		window.top?.postMessage(message, "*");
	};

	postWindowSize();

	return (
		<Container maxWidth="md" id="container" ref={ref}>
			<Container maxWidth="sm" sx={{ mb: 2 }}>
				<Typography
					variant="h3"
					component="h3"
					gutterBottom
					align="center"
				>
					Take a listen!
				</Typography>
				<Typography variant="body1" align="center" sx={{ mb: 4 }}>
					My production music library contains high quality tracks
					with many different themes ready to use in video or media
					productions. Click the link below to get to the full
					production music library. Here are a few examples to get a
					first impression:
				</Typography>
			</Container>
			<Grid container flexGrow={1} spacing={2}>
				<Grid item xs={12}>
					<Grid
						container
						justifyContent="center"
						spacing={2}
						direction="row"
					>
						{Array.from(Object.values(Tag)).map((t) => (
							<ShowCaseSongs key={t} tag={t} />
						))}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
