import { Timestamp } from "firebase/firestore";

export interface UserData {
	favorites: string[];
	newsletter: boolean;
	isAdmin?: boolean;
	email?: string;
	createdAt: Timestamp;
}

export const mapToUserData = (
	data?: Partial<UserData> | null
): Required<UserData> => {
	return {
		favorites: data?.favorites || [],
		newsletter: Boolean(data?.newsletter),
		isAdmin: Boolean(data?.isAdmin || false),
		email: data?.email || "",
		createdAt: data?.createdAt || Timestamp.now(),
	};
};
