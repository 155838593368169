import ClearIcon from "@mui/icons-material/Clear";
import {
	Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import { compareAsc } from "date-fns";
import { compareDesc } from "date-fns/esm";
import Fuse from "fuse.js";
import { debounce, uniq } from "lodash";
import React, {
	ChangeEvent,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { useFuse } from "../hooks/use-fuse.hook";
import { TrackData } from "../model/audio-file.info.model";
import { useAuthState } from "../state/auth.state";
import { logger } from "../utils/logger.util";
import { TagCloud } from "./TagCloud";
import { TrackCard } from "./track/TrackCard";
const PREFIX = "TrackList";

const classes = {
	search: `${PREFIX}-search`,
	select: `${PREFIX}-select`,
	sectionTitle: `${PREFIX}-sectionTitle`,
	tagCloud: `${PREFIX}-tagCloud`,
	formControl: `${PREFIX}-formControl`,
	filterContainer: `${PREFIX}-filterContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
	[`& .${classes.search}`]: {
		width: "100%",
		marginBottom: theme.spacing(1),
	},

	[`& .${classes.select}`]: {
		width: "100%",
	},

	[`& .${classes.sectionTitle}`]: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},

	[`& .${classes.tagCloud}`]: {
		margin: theme.spacing(1, 0, 1, 0),
	},

	[`& .${classes.formControl}`]: {},

	[`& .${classes.filterContainer}`]: {
		marginBottom: theme.spacing(2),
	},
}));

type Props = {
	files: TrackData[];
	editable?: boolean;
};

enum SortType {
	ASC = "asc",
	DESC = "desc",
	LATEST = "latest",
	OLDEST = "oldest",
}

const searchKeys: Array<keyof TrackData> = [
	"title",
	"composer",
	"artist",
	"labelcode",
	"tags",
	"description",
	"gema",
];

const fuseOptions: Fuse.IFuseOptions<TrackData> = {
	keys: searchKeys,
	threshold: 0.5,
};

const SearchInput: React.FC<{ onChange: (val: string) => void }> = ({
	onChange,
}) => {
	const ref = useRef<HTMLInputElement>(null);

	const [search, setSearch] = useState<string>("");
	const onChangeDebounced = useCallback(
		(val: any) => debounce(onChange, 100)(val),
		[onChange]
	);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChangeDebounced(e.target.value);
		setSearch(e.target.value);
	};

	const handleClearSearch = () => {
		onChangeDebounced("");
		setSearch("");
		logger.log(ref.current);
		if (ref.current) {
			ref.current.value = "";
		}
	};

	return (
		<TextField
			onChange={handleSearchChange}
			className={classes.search}
			label="Search"
			type="text"
			variant="filled"
			inputRef={ref}
			InputProps={{
				endAdornment: search ? (
					<InputAdornment position="end">
						<IconButton
							onClick={handleClearSearch}
							aria-label="clear search"
							size="large"
						>
							<ClearIcon />
						</IconButton>
					</InputAdornment>
				) : null,
			}}
		/>
	);
};

export const TrackList: React.FC<Props> = ({ files, editable }) => {
	const [search, setSearch] = useState<string>("");

	const { data } = useAuthState();
	const [sort, setSort] = useState<SortType>(SortType.LATEST);

	const filteredList = useFuse<TrackData>(
		files,
		search,
		{ limit: 20 },
		fuseOptions
	);

	const [tagFilter, setTagFilter] = useState<string[]>([]);

	const tags = useMemo(
		() => uniq<string>(files.map((f) => f.tags).flat()).sort(),
		[files]
	);

	const isFavorite = (f: TrackData) => {
		return Boolean(data?.favorites.includes(f.id));
	};

	const searchFiltered = (
		search
			? filteredList.map((fr) => fr.item)
			: files.sort((a, b) => {
					switch (sort) {
						case SortType.ASC:
							return (a.title || "").localeCompare(b.title || "");
						case SortType.DESC:
							return (b.title || "").localeCompare(a.title || "");
						case SortType.OLDEST:
							return compareDesc(
								b.createdAt.toDate(),
								a.createdAt.toDate()
							);
						case SortType.LATEST:
						default:
							return compareAsc(
								b.createdAt.toDate(),
								a.createdAt.toDate()
							);
					}
			  })
	).filter(
		(i) =>
			tagFilter.length === 0 || tagFilter.every((t) => i.tags.includes(t))
	);

	const favorites = searchFiltered.filter(isFavorite);

	const other = searchFiltered.filter((f) => !isFavorite(f));

	const handleTagSelect = (t: string) => {
		if (tagFilter.includes(t)) {
			setTagFilter(tagFilter.filter((i) => i !== t));
		} else {
			setTagFilter([...tagFilter, t]);
		}
	};

	if (files.length === 0) {
		return (
			<Typography gutterBottom variant="h6">
				No tracks found
			</Typography>
		);
	}

	const handleSortChange = (event: SelectChangeEvent<SortType>) => {
		setSort(event.target.value as SortType);
	};

	const hasFilter = tagFilter.length > 0 || search;
	const hasTagFilter = tagFilter.length > 0;

	const tagText = `${
		tagFilter.length ? `tagged with "${tagFilter.join(", ")}"` : ""
	}`.trim();

	const searchText = `matching "${search}"`;

	const filterText = `${search ? searchText : ""} ${
		hasTagFilter && search ? "," : ""
	} ${hasTagFilter ? tagText : ""}`.trim();

	const hasFavorites = favorites.length > 0;
	return (
		<Root>
			<SearchInput onChange={setSearch} />
			<Grid container spacing={3} className={classes.filterContainer}>
				<Grid item xs={12} md={3}>
					<Typography variant="body2" gutterBottom>
						Sort
					</Typography>
					<Select
						className={classes.select}
						// variant="outlined"
						labelId="sort-by"
						value={sort}
						onChange={handleSortChange}
					>
						<MenuItem value={SortType.LATEST}>
							Newest first
						</MenuItem>
						<MenuItem value={SortType.OLDEST}>
							Oldest first
						</MenuItem>
						<MenuItem value={SortType.ASC}>
							Alphabetical ascending (A-Z)
						</MenuItem>
						<MenuItem value={SortType.DESC}>
							Alphabetical descending (Z-A)
						</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={9}>
					<Typography variant="body2" gutterBottom>
						Tags
					</Typography>
					<TagCloud
						tags={tags}
						selected={tagFilter}
						onSelect={handleTagSelect}
					/>
				</Grid>
			</Grid>
			{hasFavorites ? (
				<>
					<Typography variant="h5" className={classes.sectionTitle}>
						{hasFilter ? `Favorites ${filterText}` : "Favorites"}
					</Typography>
					<Stack spacing={1}>
						{favorites.map((f) => (
							<TrackCard
								key={f.id}
								showBgImage={true}
								track={f}
								editable={editable}
								availableTags={tags}
							/>
						))}
					</Stack>
				</>
			) : null}
			{hasFavorites || hasFilter ? (
				<Typography variant="h5" className={classes.sectionTitle}>
					{hasFilter ? `Tracks ${filterText}` : "All tracks"}
				</Typography>
			) : null}
			{other.length > 0 ? (
				<Stack spacing={1}>
					{other.map((f) => (
						<TrackCard
							key={f.id}
							showBgImage={true}
							track={f}
							editable={editable}
							availableTags={tags}
						/>
					))}
				</Stack>
			) : (
				<Typography variant="body2" className={classes.sectionTitle}>
					No tracks found
				</Typography>
			)}
		</Root>
	);
};
