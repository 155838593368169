import "firebase/firestore";
import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Collection } from "../model/collection.enum";
import { mapToUserData, UserData } from "../model/user-data.model";
import { logger } from "../utils/logger.util";
type UserDataWithId = UserData & {
	id: string;
};

export function useUsers() {
	const [items, setItems] = useState<UserDataWithId[]>([]);
	const [pending, setPending] = useState<boolean>(true);
	useEffect(() => {
		return onSnapshot(
			collection(getFirestore(), Collection.USERS),
			(snapshot) => {
				setItems(
					snapshot.docs.map(
						(d) =>
							({
								id: d.id,
								...mapToUserData(d.data()),
							} as UserDataWithId)
					)
				);
				setPending(false);
			}
		);
	}, []);

	logger.log("useUsers", { items, pending });

	return { items, pending };
}
