import { getApps, initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
	collection,
	connectFirestoreEmulator,
	doc,
	enableIndexedDbPersistence,
	getFirestore,
	onSnapshot,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { TrackData } from "../model/audio-file.info.model";
import { Collection } from "../model/collection.enum";
import { FirebaseUser } from "../model/firebase.model";
import { mapToUserData } from "../model/user-data.model";
import { globalAuthState } from "../state/auth.state";
import { logger } from "../utils/logger.util";
import { createUserDoc } from "./firestore.firebase";
import { FUNCTIONS_REGION } from "./functions.firebase";
import { emulatorsEnabled } from "./util.firebase";

export function initFirebase() {
	const firebaseConfig = {
		apiKey: "AIzaSyBVcwe38ZaSiFaA6Htv1RNkEmtwkSLRNkU",
		authDomain: "nikdaijai.firebaseapp.com",
		databaseURL: "https://nikdaijai.firebaseio.com",
		projectId: "nikdaijai",
		storageBucket: "nikdaijai.appspot.com",
		messagingSenderId: "1050378684108",
		appId: "1:1050378684108:web:d3073c1e95e3dea9bd6469",
	};
	if (getApps().length === 0) {
		const app = initializeApp(firebaseConfig);
		const auth = getAuth();
		const storage = getStorage();
		const functions = getFunctions(app, FUNCTIONS_REGION);
		const firestore = getFirestore();

		onAuthStateChanged(auth, async (authUser: FirebaseUser | null) => {
			logger.log("onAuthStateChanged", authUser);
			globalAuthState().updateUser(authUser);
			if (authUser) {
				const usersCollection = collection(firestore, Collection.USERS);
				onSnapshot(doc(usersCollection, authUser.uid), (snapshot) => {
					logger.log("User snapshot", { snapshot });
					const userData = snapshot.data();
					if (!userData) {
						createUserDoc(authUser);
					}
					globalAuthState().updateUserData(mapToUserData(userData));
				});
			} else {
				globalAuthState().updateUserData(mapToUserData(null));
			}
		});
		if (emulatorsEnabled()) {
			logger.log("Using local emulators");
			connectStorageEmulator(storage, "localhost", 9199);
			connectFunctionsEmulator(functions, "localhost", 5001);
			connectFirestoreEmulator(firestore, "localhost", 8080);
		} else {
			enableIndexedDbPersistence(firestore).catch((err) => {
				if (err.code === "failed-precondition") {
					// Multiple tabs open, persistence can only be enabled
					// in one tab at a a time.
					// ...
				} else if (err.code === "unimplemented") {
					// The current browser does not support all of the
					// features required to enable persistence
					// ...
				}
			});
		}
	} else {
		logger.log("Firebase already initialized");
	}
}

export const dumpData = (files: TrackData[]) => () => {
	const json = JSON.stringify({ files }, null, 2);

	const blob = new Blob([Buffer.from(json)]);
	const blobUrl = URL.createObjectURL(blob);
	var link = document.createElement("a");
	link.href = blobUrl;
	link.download = "dump.json";
	link.target = "_blank";
	link.click();
};

export * from "./auth";
export * from "./constants";
export * from "./firestore.firebase";
export * from "./storage.firebase";
