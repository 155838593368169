import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { TrackData } from "../model/audio-file.info.model";
import { Collection } from "../model/collection.enum";

export function useAudioFiles() {
	const [items, setItems] = useState<TrackData[]>([]);
	const [pending, setPending] = useState<boolean>(true);
	useEffect(() => {
		return onSnapshot(
			collection(getFirestore(), Collection.AUDIO),
			(snapshot) => {
				setItems(
					snapshot.docs.map(
						(d) =>
							({
								id: d.id,
								...d.data(),
							} as TrackData)
					)
				);
				setPending(false);
			}
		);
	}, []);

	return { files: items, loading: pending };
}

export function useAudioFile(id?: string) {
	const [item, setItem] = useState<TrackData | null>(null);
	const [pending, setPending] = useState<boolean>(true);
	useEffect(() => {
		if (id) {
			return onSnapshot(
				doc(getFirestore(), Collection.AUDIO, id),
				(snapshot) => {
					setItem({
						id: snapshot.id,
						...snapshot.data(),
					} as TrackData);
					setPending(false);
				}
			);
		}
	}, [id]);

	return { file: item || null, loading: pending };
}
