import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { styled } from "@mui/material/styles";
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Checkbox,
	CircularProgress,
	Divider,
	Fade,
	FormControlLabel,
	Grid,
	Link,
	TextField,
	Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { GoogleLoginButton } from "react-social-login-buttons";
import {
	loginWithEmailAndPassword2,
	loginWithGoogle,
	logout,
	resetPassword,
	signUpWithEmailAndPassword,
} from "../firebase/auth";
import { useAuthState } from "../state/auth.state";
import { Modals, useModalsState } from "../state/modal.state";
import { logger } from "../utils/logger.util";
import { isValidEmail } from "../utils/validate.util";
const PREFIX = "LoginModal";

const classes = {
	modal: `${PREFIX}-modal`,
	card: `${PREFIX}-card`,
	avatar: `${PREFIX}-avatar`,
	form: `${PREFIX}-form`,
	submit: `${PREFIX}-submit`,
	divider: `${PREFIX}-divider`,
	newsletter: `${PREFIX}-newsletter`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
	[`&.${classes.modal}`]: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	[`& .${classes.card}`]: {
		maxWidth: 600,
		margin: theme.spacing(2),
		width: "100%",
		padding: theme.spacing(2),
	},

	[`& .${classes.avatar}`]: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},

	[`& .${classes.form}`]: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},

	[`& .${classes.submit}`]: {
		margin: theme.spacing(3, 0, 2),
	},

	[`& .${classes.divider}`]: {
		margin: theme.spacing(4, 0, 4, 0),
	},

	[`& .${classes.newsletter}`]: {},
}));

type FormData = {
	email: string;
	password: string;
};

enum View {
	LOGIN = "LOGIN",
	REGISTER = "REGISTER",
	RESET_PASSWORD = "RESET_PASSWORD",
}

type ViewProps = {
	onViewChange: (view: View) => unknown;
	close: () => unknown;
};

const LoginView: React.FC<ViewProps> = ({ onViewChange, close }) => {
	const [pending, setPending] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	// const {
	// 	registerEmailField,
	// 	registerPasswordField,
	// 	handleSubmit,
	// 	errors,
	// 	isValid,
	// } = useFormWithTimeoutValidate<FormData>();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<FormData>();

	const onSubmit = async ({ email, password }: FormData) => {
		setPending(true);
		try {
			await loginWithEmailAndPassword2(email, password);
			enqueueSnackbar("Welcome back!", { variant: "success" });
			close();
		} catch (error) {
			enqueueSnackbar(
				"There was an error logging you in. Please try again",
				{
					variant: "error",
				}
			);
			logger.log("error logging ing", error);
		}
		setPending(false);
	};

	const emailHasErrors = Boolean(errors.email);
	const passwordHasErrors = Boolean(errors.password);

	logger.log({ emailHasErrors, errors });

	return (
		<>
			<CardContent>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Log in
				</Typography>
				<Typography variant="body1">
					You can create an account to save your favorite tracks.
					Subscribe to the newsletter so you won't miss any updates.
					No spam. Just high quality content.
				</Typography>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit(onSubmit)}
				>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="email"
						type="email"
						label={
							emailHasErrors
								? "Please enter a valid Email Address"
								: "Email Address"
						}
						error={emailHasErrors}
						// autoFocus
						inputProps={{
							...register("email", {
								required: true,
								validate: isValidEmail,
							}),
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						label={
							passwordHasErrors
								? "Please enter a password with at least 8 characters"
								: "Password"
						}
						type="password"
						id="password"
						error={passwordHasErrors}
						autoComplete="current-password"
						inputProps={{
							...register("password", {
								required: true,
								minLength: 8,
							}),
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={pending || !isValid}
					>
						{pending ? <CircularProgress /> : "Log in"}
					</Button>
					<Grid container>
						<Grid item xs>
							<Link
								href="#"
								variant="body2"
								onClick={() =>
									onViewChange(View.RESET_PASSWORD)
								}
							>
								Forgot password?
							</Link>
						</Grid>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={() => onViewChange(View.REGISTER)}
							>
								Don't have an account? Register now
							</Link>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</>
	);
};

const RegisterView: React.FC<ViewProps> = ({ onViewChange, close }) => {
	const [pending, setPending] = useState(false);
	const [newsletter, setNewsletter] = useState(true);
	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<FormData>();

	const onSubmit = async ({ email, password }: FormData) => {
		setPending(true);
		try {
			await signUpWithEmailAndPassword(email, password, newsletter);
			enqueueSnackbar("Sign up successful. Welcome!", {
				variant: "success",
			});
			close();
		} catch (error) {
			enqueueSnackbar("There was an error signing you up", {
				variant: "error",
			});
			logger.log("error signing up", error);
		}
		setPending(false);
	};

	const handleNewsletterChange = (e: any) => {
		setNewsletter(e.target.checked);
	};

	const emailHasErrors = Boolean(errors.email);
	const passwordHasErrors = Boolean(errors.password);

	return (
		<>
			<CardContent>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Register
				</Typography>
				<Typography variant="body1">
					You can create an account to save your favorite tracks.
					Subscribe to the newsletter so you won't miss any updates.
					No spam. Just high quality content.
				</Typography>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit(onSubmit)}
				>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="email"
						type="email"
						label={
							emailHasErrors
								? "Please enter a valid Email Address"
								: "Email Address"
						}
						autoComplete="email"
						error={emailHasErrors}
						autoFocus
						inputProps={{
							...register("email", {
								required: true,
								validate: isValidEmail,
							}),
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						label={
							passwordHasErrors
								? "Please enter a password with at least 8 characters"
								: "Password"
						}
						type="password"
						id="password"
						error={passwordHasErrors}
						inputProps={{
							...register("password", {
								minLength: 8,
								required: true,
							}),
						}}
					/>
					<Typography variant="body1">
						By clicking "Register" you accept the{" "}
						<Link
							href="https://nikdaijai.com/datenschutz"
							variant="body1"
							target="_blank"
							rel="noreferrer noopener"
							style={{
								textDecoration: "underline",
							}}
						>
							Privacy Policy.
						</Link>
					</Typography>
					<FormControlLabel
						className={classes.newsletter}
						control={
							<Checkbox
								checked={newsletter}
								onChange={handleNewsletterChange}
								name="newsletter"
								color="primary"
							/>
						}
						label="Send me updates when new tracks are available."
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={pending || !isValid}
					>
						{pending ? <CircularProgress /> : "Register"}
					</Button>
					<Grid container>
						<Grid item xs>
							<Link
								href="#"
								variant="body2"
								onClick={() =>
									onViewChange(View.RESET_PASSWORD)
								}
							>
								Forgot password?
							</Link>
						</Grid>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={() => onViewChange(View.LOGIN)}
							>
								Already have an account? Log in
							</Link>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</>
	);
};

const ResetPassword: React.FC<ViewProps> = ({ onViewChange, close }) => {
	const [pending, setPending] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<Pick<FormData, "email">>();

	const onSubmit = async ({ email }: Pick<FormData, "email">) => {
		setPending(true);
		try {
			await resetPassword(email);
			enqueueSnackbar(
				"Password reset requested. Please check your emails.",
				{
					variant: "success",
				}
			);
			close();
		} catch (error) {
			enqueueSnackbar(
				"There was an error resetting your password. Please try again",
				{ variant: "error" }
			);
			logger.log("error resetting password", error);
		}
		setPending(false);
	};

	const emailHasErrors = Boolean(errors.email);

	return (
		<>
			<CardContent>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5" gutterBottom>
					Forgot your password?
				</Typography>
				<Typography variant="body1">
					Enter your Email Address and you will receive instructions
					on how to enter a new password.
				</Typography>
				<form
					className={classes.form}
					noValidate
					onSubmit={handleSubmit(onSubmit)}
				>
					<TextField
						variant="outlined"
						margin="normal"
						fullWidth
						id="email"
						type="email"
						label={
							emailHasErrors
								? "Please enter a valid Email Address"
								: "Email Address"
						}
						autoComplete="email"
						error={emailHasErrors}
						autoFocus
						inputProps={{
							...register("email", {
								required: true,
								validate: isValidEmail,
							}),
						}}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled={pending || !isValid}
					>
						{pending ? (
							<CircularProgress />
						) : (
							"Send password reset email"
						)}
					</Button>
					<Grid container>
						<Grid item>
							<Link
								href="#"
								variant="body2"
								onClick={() => onViewChange(View.LOGIN)}
							>
								Already have an account? Log in
							</Link>
						</Grid>
					</Grid>
				</form>
			</CardContent>
		</>
	);
};

export const LoginModal: React.FC = () => {
	const { isOpen, close } = useModalsState();
	const [view, setView] = useState(View.REGISTER);
	const { user } = useAuthState();

	const handleViewChange = (v: View) => {
		setView(v);
	};

	return (
		<StyledModal
			disablePortal
			aria-labelledby="Authentication modal"
			className={classes.modal}
			open={isOpen(Modals.LOGIN)}
			onClose={close}
			closeAfterTransition
			disableAutoFocus={true}
		>
			<Fade in={isOpen(Modals.LOGIN)}>
				<Card className={classes.card}>
					{user ? (
						<>
							<CardContent>
								<Typography variant="h4" gutterBottom>
									You are logged in.
								</Typography>
							</CardContent>
							<CardActions>
								<Button onClick={logout}>Logout</Button>
								<Button variant="contained" onClick={close}>
									Close
								</Button>
							</CardActions>
						</>
					) : (
						<>
							{view === View.LOGIN ? (
								<LoginView
									close={close}
									onViewChange={handleViewChange}
								/>
							) : null}
							{view === View.REGISTER ? (
								<RegisterView
									close={close}
									onViewChange={handleViewChange}
								/>
							) : null}
							{view === View.RESET_PASSWORD ? (
								<ResetPassword
									close={close}
									onViewChange={handleViewChange}
								/>
							) : null}
							<Divider />
							<CardContent>
								<GoogleLoginButton onClick={loginWithGoogle} />
							</CardContent>
						</>
					)}
				</Card>
			</Fade>
		</StyledModal>
	);
};
