import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useRouteError } from "react-router-dom";

export const ErrorPage = () => {
	const error = useRouteError();
	console.error(error);
	return (
		<Container sx={{ mt: 6 }} maxWidth="lg">
			<Stack
				spacing={1}
				sx={{ mt: 8 }}
				justifyContent="flex-start"
				direction="column"
			>
				<Typography variant="h4">Oh no!</Typography>
				<Typography variant="h5">
					Something went terribly wrong
				</Typography>
				<Box>
					<Button variant="contained">Take me home</Button>
				</Box>
			</Stack>
		</Container>
	);
};
