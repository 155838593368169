import { List, ListItem, ListItemText } from "@mui/material";
import { compareAsc, formatDistanceToNow } from "date-fns";
import React from "react";
import { useDownloads } from "../hooks/downloads.hook";

export const DownloadList: React.FC = () => {
	const { items } = useDownloads();
	return (
		<List>
			{items
				.slice()
				.sort((a, b) =>
					compareAsc(b.downloadedAt.toDate(), a.downloadedAt.toDate())
				)
				.map((i) => {
					const dateDistance = formatDistanceToNow(
						i.downloadedAt.toDate(),
						{
							addSuffix: true,
						}
					);

					const userEmail =
						i.user?.email && i.user?.email !== "unknown"
							? i.user?.email
							: null;
					const secondary = `${dateDistance} by ${
						userEmail ||
						`${i.clientIp} (not logged in)` ||
						"unknown"
					}`;
					return (
						<ListItem key={i.id}>
							<ListItemText
								primary={i.file.title}
								secondary={secondary}
							/>
						</ListItem>
					);
				})}
		</List>
	);
};
