import { trackPlay } from "../firebase/functions.firebase";
import { TrackData } from "../model/audio-file.info.model";
import { globalPlayerState } from "../state/player.state";
import { getAudioFileBuffer } from "./audio-cache.util";
import { logger } from "./logger.util";

const AUDIO_TAG_ID = "app-audio-player";
const SILENCE = "/silence.mp3";

export const seekTo = async (file: TrackData, progress: number) => {
	const player = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;

	if (!player) {
		return;
	}

	const { audioId, duration } = globalPlayerState();

	if (audioId !== file.id) {
		await playAudio(file);
	}

	const currentTime = duration * progress;
	player.currentTime = currentTime;

	if (player.paused) {
		await player.play();
	}
};

export const setVolume = async (volume: number) => {
	const player = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;

	if (!player) {
		return;
	}

	setDefaultVolume(volume);

	try {
		player.volume = volume / 100;
	} catch (error) {
		logger.error("error setting volume", error);
	}
};

export const pauseAudio = () => {
	const player = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;

	if (!player) {
		return;
	}

	if (!player.paused) {
		player.pause();
	}
};

export const unpauseAudio = async () => {
	const player = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;

	if (!player) {
		return;
	}

	if (player.paused) {
		await player.play();
	}
};

export const playAudio = async (track: TrackData) => {
	const player = document.getElementById(AUDIO_TAG_ID) as HTMLAudioElement;

	if (!player) {
		return;
	}

	setVolume(getDefaultVolumeFromLocalStorage());
	// first play so the user interaction gets tracked
	player.src = SILENCE;
	try {
		await player.play();
	} catch (error) {
		logger.log("Error during focus play", error);
	}

	const { state } = globalPlayerState();

	if (!player.paused) {
		player.pause();
	}

	state.set((s) => ({
		...s,
		fetching: true,
		audioId: track.id,
		progress: 0,
	}));

	const buffer = await getAudioFileBuffer(track);
	if (!buffer) {
		state.fetching.set(false);
		return;
	}
	state.set((s) => ({
		...s,
		fetching: false,
		buffer: buffer,
	}));

	try {
		const blob = new Blob([buffer], { type: "audio/mp3" });
		const blobUrl = window.URL.createObjectURL(blob);

		player.src = blobUrl;
		trackPlay(track);

		player.onplaying = (e) => {
			state.playing.set(true);
		};

		player.onerror = (e) => {
			logger.error("player error", e);
		};

		player.ontimeupdate = (e) => {
			const currentTime = player.currentTime;
			const duration = player.duration;
			if (duration > 0) {
				const progress = currentTime / duration;
				state.progress.set(progress);
			}
		};

		player.onpause = () => {
			state.playing.set(false);
		};

		player.ondurationchange = (e) => {
			state.duration.set(player.duration || 0);
		};

		player.onvolumechange = (e) => {
			state.volume.set(Math.max(player.volume * 100 || 0, 0));
		};

		if (player.paused) {
			player.currentTime = 0;
			await player.play();
			player.currentTime = 0;
		}
	} catch (error) {
		logger.error("error playing", error);
	}
};

export const getDefaultVolumeFromLocalStorage = () => {
	try {
		const rawValue = localStorage.getItem("user-volume");
		if (rawValue) {
			return Number(rawValue);
		}
	} catch (error) {
		logger.log("Error gettin default volume from localstorage");
	}

	return 100;
};

export const setDefaultVolume = (volume: number) => {
	try {
		localStorage.setItem("user-volume", String(volume));
	} catch (error) {
		logger.log("Error setting default volume to localstorage");
	}
};
