export const isDevelopmentEnvironment = () =>
	process.env.NODE_ENV && process.env.NODE_ENV === "development";

export const isDebugEnabled = () => {
	try {
		const flag = localStorage.getItem("debug");
		return Boolean(flag);
	} catch (error) {
		console.error("Error reading debug setting");
		return false;
	}
};
export const isDevOrDebug = () => {
	return isDevelopmentEnvironment() || isDebugEnabled();
};
