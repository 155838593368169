import { State } from "@hookstate/core";
import { logStateEventGrouped } from "../../utils/logger.util";

export const createLoggingPlugin = <T>(label: string) => {
	return function StateLoggingPlugin() {
		return {
			id: Symbol("StateLoggingPlugin"),
			init: (s: State<T>) => {
				// logStateEventGrouped(label, "STATE_INIT", s.value, {});
				return {
					onSet: (data: any) => {
						logStateEventGrouped(
							label,
							`SET ${data.path}`.trim(),
							data.value,
							data.state
						);
						// console.log("new state set", data.state);
						// console.log("at path", data.path);
						// console.log("to a new value", data.value);
						// console.log("from old value", data.previous);
						// console.log("merged with", data.merged);
					},
					onDestroy: (data: any) => {
						logStateEventGrouped(
							label,
							"DESTROYED",
							data.state,
							data.state
						);
						// console.log("state detroyed", data.state);
					},
					// onBatchStart: (data: any) => {
					//   logStateEventGrouped(label, "BATCH_START", data.state, data.state);
					//   // console.log("batch started", data.state);
					//   // console.log("at path", data.path);
					//   // console.log("with context", data.context);
					// },
					// onBatchFinish: (data: any) => {
					//   logStateEventGrouped(label, "BATCH_FINISH", data.state, data.state);
					//   // console.log("batch finished", data.state);
					//   // console.log("with context", data.context);
					// },
				};
			},
		};
	};
};
