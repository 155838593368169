import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	Container,
	FormControlLabel,
	Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { TrackCard } from "../components/track/TrackCard";
import { UserAvatar } from "../components/UserAvatar";
import { sendVerifyEmail, setNewsletter } from "../firebase";
import { useAudioFiles } from "../hooks/audio-files.hook";
import { useAuthState } from "../state/auth.state";

const PREFIX = "ProfileView";

const classes = {
	sectionTitle: `${PREFIX}-sectionTitle`,
};

const VerifyEmail: React.FC = () => {
	const [verificationStarted, setVerficationStarted] = useState(false);
	const sendVerification = () => {
		setVerficationStarted(true);
		sendVerifyEmail();
	};
	return (
		<CardContent>
			<Typography variant="h4">Verify email</Typography>
			<Typography variant="body1" gutterBottom>
				By verifying your email you further secure your account.
			</Typography>
			{verificationStarted ? (
				<Typography>A verification email is on its way...</Typography>
			) : (
				<Button variant="contained" onClick={sendVerification}>
					Start verification
				</Button>
			)}
		</CardContent>
	);
};

const Newsletter: React.FC = () => {
	const {
		user,
		data: { newsletter },
	} = useAuthState();

	if (!user) {
		return null;
	}
	const handleNewsletterChange = (e: any) => {
		setNewsletter(user, e.target.checked);
	};
	return (
		<CardContent>
			<FormControlLabel
				control={
					<Checkbox
						checked={newsletter}
						onChange={handleNewsletterChange}
						name="newsletter"
						color="primary"
					/>
				}
				label="Send me updates when new tracks are available."
			/>
		</CardContent>
	);
};

const UserFavorites: React.FC = () => {
	const {
		data: { favorites },
	} = useAuthState();

	const { files } = useAudioFiles();

	const favFiles = files.filter((f) => favorites.includes(f.id));

	if (favFiles.length === 0) {
		return null;
	}

	return (
		<>
			<Typography variant="h5" className={classes.sectionTitle}>
				My Favorites
			</Typography>
			<Stack spacing={1}>
				{favFiles.map((f) => (
					<TrackCard key={f.id} track={f} editable={false} />
				))}
			</Stack>
		</>
	);
};

export const ProfileView: React.FC = () => {
	const { user } = useAuthState();
	return (
		<Container maxWidth="lg" sx={{ my: 2 }}>
			<Stack spacing={2}>
				<Typography variant="h4" component="h4">
					Profile
				</Typography>
				<Card>
					<CardHeader
						avatar={<UserAvatar size="44px" />}
						title={user?.email}
					></CardHeader>
					{user?.emailVerified ? null : <VerifyEmail />}
					<Newsletter />
				</Card>

				<UserFavorites />
			</Stack>
		</Container>
	);
};
