import { default as AccountCircle } from "@mui/icons-material/AccountCircle";
import Avatar from "react-avatar";
import { useAuthState } from "../state/auth.state";

type Props = {
	size?: string;
};

export const UserAvatar = ({ size }: Props) => {
	const { user } = useAuthState();

	return user ? (
		<Avatar
			src={user.photoURL || undefined}
			email={user.email || undefined}
			name={user.displayName || user.email || undefined}
			round
			size={size}
			color={"#FFFFFF"}
			fgColor={"#000000"}
		/>
	) : (
		<AccountCircle />
	);
};
