import AttachFileIcon from "@mui/icons-material/AttachFile";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { logger } from "../utils/logger.util";

const DropArea = styled("div")(({ theme }) => ({
	minHeight: 0,
	backgroundColor: theme.palette.grey[900],
	padding: theme.spacing(2),
	borderColor: theme.palette.grey[700],
	borderRadius: 4,
	borderStyle: "dashed",
	borderWidth: 1,
	color: theme.palette.grey[500],
	cursor: "pointer",
}));

type Props = PropsWithChildren<{
	onUpload: (files: File[]) => Promise<void>;
	accept: AcceptedFileType;
}>;

export enum AcceptedFileType {
	IMAGE = "image/*",
	AUDIO = "audio/*",
}

export const FileUploadArea = ({ onUpload, accept, children }: Props) => {
	const [files, setFiles] = useState<File[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const onDrop = useCallback((acceptedFiles: File[]) => {
		// Do something with the files
		setFiles(acceptedFiles);
	}, []);

	const onDropRejected = (rejectedFiles: FileRejection[]) => {
		logger.log("rejected", rejectedFiles);
		for (const file of rejectedFiles) {
			enqueueSnackbar(
				`File ${file.file.name} rejected: ${file.errors
					.map((e) => e.message)
					.join(", ")}`,
				{ variant: "error" }
			);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		onDropRejected,
		// 100mb
		maxSize: 10485760 * 10,
		accept: {
			[accept]: [],
		},
	});

	const removeSelected = (file: File) => (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setFiles(files.filter((f) => f.name !== file.name));
	};

	const iconForFileType = (f: File) => {
		console.log("file", f.name, f.type);
		if (f.type.includes("image")) {
			return <InsertPhotoIcon />;
		}

		if (f.type.includes("audio")) {
			return <AudiotrackIcon />;
		}
		return <AttachFileIcon />;
	};

	const handleUpload = async () => {
		try {
			setFiles([]);
			await onUpload(files);
		} catch (error: any) {
			enqueueSnackbar("Error uploading file " + error.message, {
				variant: "error",
			});
			logger.log("Error uploading file ", error);
		}
	};

	return (
		<Card sx={{ my: 2 }}>
			<CardContent>
				<DropArea {...getRootProps()}>
					<input {...getInputProps()} />
					{files.length > 0 ? (
						<Grid item xs={12} md={8}>
							<List dense={true}>
								{files.map((f) => (
									<ListItem
										key={f.name}
										secondaryAction={
											<IconButton
												onClick={removeSelected(f)}
												edge="end"
												aria-label="delete"
											>
												<DeleteIcon />
											</IconButton>
										}
									>
										<ListItemAvatar>
											<Avatar>
												{iconForFileType(f)}
											</Avatar>
										</ListItemAvatar>
										<ListItemText primary={f.name} />
									</ListItem>
								))}
							</List>
						</Grid>
					) : children ? (
						children
					) : (
						<p>Drag files here, or click to select</p>
					)}
				</DropArea>
			</CardContent>
			<CardActions>
				{files.length > 0 ? (
					<Button
						disabled={files.length === 0}
						onClick={handleUpload}
					>
						Upload
					</Button>
				) : null}
			</CardActions>
		</Card>
	);
};
