import { State, useState } from "@hookstate/core";
import { createMonitoredState } from "./utils/create-store";
type PlayerState = {
	src: string | null;
	buffer: Buffer | null;
	audioId: string | null;
	duration: number;
	urlCache: { [key: string]: string };
	volume: number;
	progress: number;
	fetching: boolean;
	playing: boolean;
};

const state = createMonitoredState<PlayerState>(
	{
		src: null,
		audioId: null,
		buffer: null,
		volume: 100,
		duration: 0,
		urlCache: {},
		progress: 0,
		playing: false,
		fetching: false,
	},
	{ label: "player" }
);

const wrapper = (state: State<PlayerState>) => ({
	get src() {
		return state.src.get();
	},
	get buffer() {
		return state.buffer.get();
	},
	get fetching() {
		return state.fetching.get();
	},
	get audioId() {
		return state.audioId.get();
	},
	get volume() {
		return state.volume.get();
	},
	get progress() {
		return state.progress.get();
	},
	get playing() {
		return state.playing.get();
	},
	get duration() {
		return state.duration.get();
	},
	get state() {
		return state;
	},
});

export const globalPlayerState = () => wrapper(state);
export const usePlayerState = () => wrapper(useState(state));
