import { validate } from "validate.js";

export const isValidEmail = (email: string) => {
	const result = validate(
		{ email },
		{
			email: {
				email: true,
			},
		}
	);
	return !result || (result && result.length === 0);
};
